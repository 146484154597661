import { useEffect, useRef, useState } from "react";

import "../../styles/elements/triangles.css";
import { CHoverInfo } from "./hoverInfo";

const CLASS_TRIANGLE_BOX = "triangle_box";
const CLASS_TRIANGLE_BOX_INNER = "triangle_box_inner";
const CLASS_TRIANGLE_POSITION_WRAPPER = "triangle_position_wrapper";
const CLASS_TRIANGLE_CLIP_WRAPPER = "triangle_clip_wrapper";


/**
 * @param {Object} props 
 * @param {React.JSX.Element} props.children
 * @param {number} [props.triangle60]
 * @param {number} [props.leftInner]
 * @param {number} [props.rightInner]
 * @param {import("../definitions/elements/info.defs").Info} [props.info]
 * @returns {React.JSX.Element}
 */
export function CTriangleBox({children, triangle60=false, leftInner=false, rightInner=false, info}) {
    const [ref, height] = useTriangleHeightObserver();
    const leftTriangle = triangle60 ? 
        <CTriangleLeft60 height={height} /> : 
        leftInner ? 
            <CTriangleLeftInner height={height} /> : 
            <CTriangleLeft height={height} />;
    const rightTriangle = triangle60 ? 
        <CTriangleRight60 height={height} /> : 
        rightInner ? 
            <CTriangleRightInner height={height} /> : 
            <CTriangleRight height={height} />;
    return (
        <div className={CLASS_TRIANGLE_BOX}>
            {leftTriangle}
            <div ref={ref} className={CLASS_TRIANGLE_BOX_INNER}>
                {info && <CHoverInfo info={info} fadeDown={true} />}
                {children}
            </div>
            {rightTriangle}
        </div>
    );
}


/**
 * @returns {[React.MutableRefObject, number]} 
 */
function useTriangleHeightObserver() {
    const [height, setHeight] = useState(0);
    const observedElementRef = useRef(null);

    useEffect(() => {
        if (!observedElementRef.current) return;
        const observer = new ResizeObserver(entries => {
            setHeight(entries[0].borderBoxSize[0].blockSize);
        });
        observer.observe(observedElementRef.current);
        return () => observer.disconnect();
    }, []);

    return [observedElementRef, height];
}

/**
 * @param {Object} props 
 * @param {React.JSX.Element} props.children
 * @returns {React.JSX.Element}
 */
function CTriangleWrapper({children}) {
    return (
        <div className={CLASS_TRIANGLE_POSITION_WRAPPER}>
            <div className={CLASS_TRIANGLE_CLIP_WRAPPER}>
                {children}
            </div>
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.height
 * @returns {React.JSX.Element}
 */
function CTriangleLeft({height}) {
    return (
        <CTriangleWrapper>
            <div className="triangle_left" style={{height: height + "px"}}></div>
        </CTriangleWrapper>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.height
 * @returns {React.JSX.Element}
 */
function CTriangleRight({height}) {
    return (
        <CTriangleWrapper>
            <div className="triangle_right" style={{height: height + "px"}}></div>
        </CTriangleWrapper>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.height
 * @returns {React.JSX.Element}
 */
function CTriangleLeftInner({height}) {
    return (
        <CTriangleWrapper>
            <div className="triangle_left_inner" style={{height: height + "px"}}></div>
        </CTriangleWrapper>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.height
 * @returns {React.JSX.Element}
 */
function CTriangleRightInner({height}) {
    return (
        <CTriangleWrapper>
            <div className="triangle_right_inner" style={{height: height + "px"}}></div>
        </CTriangleWrapper>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.height
 * @returns {React.JSX.Element}
 */
function CTriangleLeft60({height}) {
    return (
        <CTriangleWrapper>
            <div className="triangle_left60" style={{height: height + "px"}}></div>
        </CTriangleWrapper>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.height
 * @returns {React.JSX.Element}
 */
function CTriangleRight60({height}) {
    return (
        <CTriangleWrapper>
            <div className="triangle_right60" style={{height: height + "px"}}></div>
        </CTriangleWrapper>
    );
}