
import * as d3 from "d3"

/**
 * 
 * @param {d3.Selection} parentDivs 
 * @param {string} siblingSelector 
 * @param {boolean} [leftInner=false] 
 * @param {boolean} [rightInner=false] 
 */
export function addEdges(parentDivs, siblingSelector, leftInner = false, rightInner = false) {
    parentDivs.each(function() {
        let parentDiv = d3.select(this);
        let left = parentDiv.insert("div", siblingSelector)
            .attr("class", leftInner ? "edge_inner_left" : "edge_left");
        let afterElement = parentDiv.select(siblingSelector + " + *");
        let right = (afterElement.empty() ? parentDiv.append("div") : parentDiv.insert("div", afterElement))
            .attr("class", rightInner ? "edge_inner_right" : "edge_right");
        let height = parentDiv.node().offsetHeight;
        left.style("height", height);
        left.style("width", height / 2);
        right.style("height", height);
        right.style("width", height / 2);
        let sibling = parentDiv.select(siblingSelector);
        let observer = new ResizeObserver(() => {
            let height = sibling.node().getBoundingClientRect().height;
            left.style("height", height);
            left.style("width", height / 2);
            right.style("height", height);
            right.style("width", height / 2);
        });
        observer.observe(sibling.node());
    });
}