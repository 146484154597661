import { GUIObject } from "../guiObject.js";
import * as d3 from "d3"

/**
 * @typedef State_Bet_Pool
 * @property {PHASE} phase
 * @property {number} bet_min
 * @property {number} bet_max
 * @property {boolean} except_zero
 * @property {Object[]} teams
 * @property {number} teams[].id
 * @property {number} teams[].pool
 * @property {number} teams[].bet
 */

/**
 * @enum {number}
 */
const PHASE = {
    /**
     * The teams can bet.
     */
    BET: 1,
    /**
     * Shows the bets to all.
     */
    SHOW: 2,
    /**
     * Disables all.
     */
    DISABLED: 3
}

/**
 * @enum {number}
 */
const ROLE = {
    /**
     * - See everything.
     * - Edit everything.
     */
    ADMIN: 1,
    /**
     * - See everything in {@link PHASE.SHOW} otherwise nothing.
     * - Edit nothing.
     */
    SPECTATOR: 2,
    /**
     * - See the own team bet and everthing in {@link PHASE.SHOW}.
     * - Edit own team bet.
     */
    TEAM: 3
}

/**
 * @enum {string}
 */
const MESSAGE_TYPE = {
    /**
     * Sets the bet of a team.
     * Only {@link ROLE.TEAM} for the own team.
     * Only in {@link PHASE.BET}.
     * @param {number} bet
     */
    BET: 1,
    /**
     * Shows the bets.
     * Only {@link ROLE.ADMIN}.
     * Only in {@link PHASE.BET}.
     */
    SHOW: 2,
    /**
     * Disables all.
     */
    DISABLE: 3,
    /**
     * Enable betting.
     */
    ENABLE: 4,
    /**
     * Sets the bet pool of a team.
     * Only {@link ROLE.ADMIN}.
     * @param {number} team_id
     * @param {number} pool
     */
    CHANGE_POOL: 5
}

/**
 * classid: "bet_pool"
 * @extends GUIObject<State_Bet_Pool>
 */
export class Bet_Pool extends GUIObject {

    /**
     * @type {d3.Selection}
     */
    betDivs;

    constructor(guiManager, message) {
        super(guiManager, message);

        this.betDivs = d3.selectAll(".scoreboard_team").filter(d => this.state.teams.some(t => t.id == d.id)).select(".scoreboard_team_outerContent").append("div")
            .attr("class", "bet_pool")
            .html(HTML);

        if (this.stateInfo.role == ROLE.TEAM) {
            this.betDivs.select(".bet")
                .attr("contenteditable", true)
                .classed("editable", true)
                .on("keydown", (e, d) => {
                    e.stopPropagation();
                    if (e.key == "Escape") {
                        d3.select(e.currentTarget).text(this.state.teams.find(t => t.id == d.id).bet);
                        return;
                    }
                    if (e.key != "Enter") return;
                    e.preventDefault();
                    let text = d3.select(e.currentTarget).text();
                    let number = parseInt(text);
                    if (number == "NaN") return;
                    if (number == this.state.teams.find(t => t.id == d.id).bet) return;
                    this.sendMessage({
                        type: MESSAGE_TYPE.BET,
                        bet: number
                    });
                })
                .on("focusout", (e, d) => {
                    let text = d3.select(e.currentTarget).text();
                    let number = parseInt(text);
                    if (number == "NaN") return;
                    if (number == this.state.teams.find(t => t.id == d.id).bet) return;
                    this.sendMessage({
                        type: MESSAGE_TYPE.BET,
                        bet: number
                    });
                })
        }
        if (this.stateInfo.role == ROLE.ADMIN) {
            this.betDivs.select(".pool")
                .attr("contenteditable", true)
                .classed("editable", true)
                .on("keydown", (e, d) => {
                    e.stopPropagation();
                    if (e.key == "Escape") {
                        d3.select(e.currentTarget).text(this.state.teams.find(t => t.id == d.id).pool);
                        return;
                    }
                    if (e.key != "Enter") return;
                    e.preventDefault();
                    let text = d3.select(e.currentTarget).text();
                    let number = parseInt(text);
                    if (number == "NaN") return;
                    if (number == this.state.teams.find(t => t.id == d.id).pool) return;
                    this.sendMessage({
                        type: MESSAGE_TYPE.CHANGE_POOL,
                        team_id: d.id,
                        pool: number
                    });
                })
                .on("focusout", (e, d) => {
                    let text = d3.select(e.currentTarget).text();
                    let number = parseInt(text);
                    if (number == "NaN") return;
                    if (number == this.state.teams.find(t => t.id == d.id).pool) return;
                    this.sendMessage({
                        type: MESSAGE_TYPE.CHANGE_POOL,
                        team_id: d.id,
                        pool: number
                    });
                })
        }
    }

    destroy() {
        this.betDivs.remove();
    }

    update() {
        this.betDivs.classed("empty", d => this.state.teams.find(t => t.id == d.id).pool == 0);
        this.betDivs.select(".bet")
            .text(d => this.state.teams.find(t => t.id == d.id).bet);
        this.betDivs.select(".pool")
            .text(d => this.state.teams.find(t => t.id == d.id).pool);
    }
}

const HTML = `
<div class="bet"></div>
<div class="pool"></div>
`;