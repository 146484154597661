
import pixel from "string-pixel-width";
const PIXEL_SETTINGS = { bold: false, font: "arial", italic: false, size: 1 };

/**
 * @param {string} string 
 * @returns {number}
 */
export function calculatePixelWidth(string) {
    return pixel(string, PIXEL_SETTINGS);
}

/**
 * @param {string[]} strings 
 * @returns {number}
 */
export function calculateMaxPixelWidth(strings) {
    return strings.reduce((a, b) => Math.max(a, calculatePixelWidth(b)), 0);
}