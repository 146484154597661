import { PhaseButton } from "../elements/phaseButton.js";
import { GUIManager } from "../guiManager.js";
import { Multiquestion, Subquestion } from "./multiquestion.js";
import * as d3 from "d3"

/**
 * @typedef {import("./multiquestion.js").State_Multiquestion} State_Question_Clue
 * @property {Object[]} teams
 * @property {number} teams[].id
 * @property {boolean} teams[].joker
 */

/**
 * @enum {number}
 */
const MESSAGE_MULTI_CLUE = {
    /**
     * Uses the joker.
     * Only {@link ROLE_MULTI.TEAM}
     */
    JOKER: 1,
    /**
     * Gives a joker to a team.
     * Only {@link ROLE_MULTI.ADMIN}
     * @param {number} team_id
     */
    GIVE_JOKER: 2,
    /**
     * Removes a joker from a team.
     * Only {@link ROLE_MULTI.ADMIN}
     * @param {number} team_id
     */
    REMOVE_JOKER: 3
}

/**
 * @enum {string}
 * @private
 */
const ROLE_MULTI = {
    ADMIN: 1,
    TEAM: 2,
    SPECTATOR: 3
}

/**
 * classid "question_clue"
 * @abstract
 * @template {State_Question_Clue} S
 * @extends Multiquestion<S>
 */
export class Question_Clue extends Multiquestion {

    /**
     * @type {d3.Selection}
     */
    jokerDivs;

    /**
     * @param {GUIManager} guiManager 
     * @param {*} message 
     */
    constructor(guiManager, message) {
        super(guiManager, message, [ROLE_MULTI.ADMIN]);

        this.jokerDivs = d3.selectAll(".scoreboard_team").select(".scoreboard_team_outerContent").append("div")
            .attr("class", "question_clue_joker");

        this.jokerDivs.append("div").attr("class", "joker_icon");

        if (this.stateInfo.role == ROLE_MULTI.TEAM) {
            this.jokerDivs.filter(d => d.id == this.stateInfo.team_id)
                .classed("own", true)
                .on("click", () => this.sendMessage({
                    type: MESSAGE_MULTI_CLUE.JOKER
                }));
        } else if (this.stateInfo.role == ROLE_MULTI.ADMIN) {
            this.jokerDivs.append("div")
                .attr("class", "joker_give")
                .on("click", (_, d) => this.sendMessage({
                    type: MESSAGE_MULTI_CLUE.GIVE_JOKER,
                    team_id: d.id
                }));
            this.jokerDivs.append("div")
                .attr("class", "joker_remove")
                .on("click", (_, d) => this.sendMessage({
                    type: MESSAGE_MULTI_CLUE.REMOVE_JOKER,
                    team_id: d.id
                }));
        }

        this.update();
    }

    destroy() {
        this.jokerDivs.remove();

        super.destroy();
    }

    update() {
        super.update();

        this.jokerDivs.classed("available", d => this.state.teams.find(t => t.id == d.id).joker);
    }
}


const BUTTON_TEXT_SHOW_HINT = "Nächster Hinweis";
const BUTTON_TEXT_SHOW_RESULT = "Lösung";
const BUTTON_TEXT_WRONG_ANSWER = "Falsche Antwort";

/**
 * @enum {string}
 */
const ROLE = {
    ADMIN: 1,
    PLAYER: 2
}

/**
 * @enum {number}
 */
const PHASE = {
    /**
     * Nothing visible
     */
    START: 1,
    /**
     * Buzzer is open. 
     * Hints will appear after a short timer expires. 
     * Teams have to buzzer and guess.
     */
    QUESTION: 2,
    /**
     * Either a team won or all teams are out. 
     * Admin can show hints without timer.
     */
    RESULT: 3,
    /**
     * Teams will get their points. 
     * All hints visible.
     */
    FINISHED: 4
}

/**
 * @enum {number}
 */
const MESSAGE_SUB_CLUE = {
    /**
     * Starts the next phase.
     * Only {@link ROLE.ADMIN}
     */
    NEXT_PHASE: 1,
    /**
     * Shows the next hint.
     * Only in {@link PHASE.QUESTION} und {@link PHASE.RESULT}.
     * Only {@link ROLE.ADMIN}.
     */
    SHOW_NEXT_HINT: 2,
    /**
     * Shows the result.
     * Only in {@link PHASE.RESULT}.
     * Only {@link ROLE.ADMIN}.
     */
    SHOW_RESULT: 3,
    /**
     * Wrong answer for the current team.
     * Only in {@link PHASE.QUESTION}.
     * Only {@link ROLE.ADMIN}.
     */
    WRONG_ANSWER: 4,
    /**
     * Shows the next hint after the question is already answered.
     * Only in {@link PHASE.QUESTION} und {@link PHASE.RESULT}.
     * Only {@link ROLE_SUB.ADMIN}.
     */
    SHOW_NEXT_HINT_AFTER: 5
}

const PHASE_NAMES = new Map([
    [1, "Start"],
    [2, "Hinweise"],
    [3, "Lösung"],
    [4, "Ende"]
]);

/**
 * @typedef {Object} Subquestion_Clue_State
 * @property {PHASE} phase
 * @property {Object[]} hints
 * @property {Object} [hints[].hint]
 * @property {boolean} hints[].visible
 * @property {boolean} buzzed
 * @property {boolean} result_visible
 * @property {string} result
 */


/**
 * @template {Subquestion_Clue_State} S
 * @extends Subquestion<S>
 * @abstract
 */
export class Subquestion_Clue extends Subquestion {

    /**
     * Div for all DOM elements of the Subquestion_Clue
     * @type {d3.Selection}
     */
    divSubquestionClue;

    /**
     * Div for all hint DOM elements.
     * @type {d3.Selection}
     */
    divHints;

    /**
     * Button to switch to next phase.
     * @type {?PhaseButton}
     */
    phaseButton;

    /**
     * Button to show the next hint.
     * @type {?d3.Selection}
     */
    nextHintButton;

    /**
     * Button to show the next hint if the question is already answered.
     * @type {?d3.Selection}
     */
    nextHintButtonAfter;

    /**
     * Button if a team give the wrong answer.
     * @type {?d3.Selection}
     */
    wrongAnswerButton;

    /**
     * Button to show the result.
     * @type {?d3.Selection}
     */
    showResultButton;

    /**
     * Button to show the result.
     * @type {?d3.Selection}
     */
    phaseButton;


    /**
     * 
     * @param {GUIManager} guiManager 
     * @param {*} message 
     */
    constructor(guiManager, message) {
        super(guiManager, message);

        this.divSubquestion.html(HTML_SUBQUESTION_CLUE);
        this.divSubquestionClue = this.divSubquestion.select("#subquestion_clue");
        this.divHints = this.divSubquestionClue.select("#subquestion_clue_hints");
        this.divResult = this.divSubquestionClue.select("#subquestion_clue_result");

        if (this.stateInfo.role == ROLE.ADMIN) {
            let controlDiv = this.divSubquestionClue.select("#subquestion_clue_controls");
            controlDiv.classed("hidden", false);

            this.phaseButton = new PhaseButton(this, controlDiv, PHASE_NAMES, this.state.phase, () => ({ type: MESSAGE_SUB_CLUE.NEXT_PHASE }));

            this.nextHintButton = controlDiv.append("div")
                .attr("class", "standardButton")
                .html(BUTTON_TEXT_SHOW_HINT)
                .on("click", () => {
                    let message = {
                        type: MESSAGE_SUB_CLUE.SHOW_NEXT_HINT
                    };
                    this.sendMessage(message);
                });
            this.nextHintButtonAfter = controlDiv.append("div")
                .attr("class", "standardButton")
                .html(BUTTON_TEXT_SHOW_HINT)
                .on("click", () => {
                    let message = {
                        type: MESSAGE_SUB_CLUE.SHOW_NEXT_HINT_AFTER
                    };
                    this.sendMessage(message);
                });
            this.showResultButton = controlDiv.append("div")
                .attr("class", "standardButton")
                .html(BUTTON_TEXT_SHOW_RESULT)
                .on("click", () => {
                    let message = {
                        type: MESSAGE_SUB_CLUE.SHOW_RESULT
                    };
                    this.sendMessage(message);
                });
            this.wrongAnswerButton = controlDiv.append("div")
                .attr("class", "standardButton")
                .html(BUTTON_TEXT_WRONG_ANSWER)
                .on("click", () => {
                    let message = {
                        type: MESSAGE_SUB_CLUE.WRONG_ANSWER
                    };
                    this.sendMessage(message);
                });
        }
    }

    destroy() {
        this.divSubquestionClue.remove();
        super.destroy();
    }

    update() {
        super.update();

        this.divSubquestionClue.select(".counter")
            .text(this.state.hints.filter(h => h.visible).length + " / " + this.state.hints.length);

        if (this.stateInfo.role == ROLE.ADMIN) {
            this.phaseButton.setPhase(this.state.phase);

            this.nextHintButton.classed("invisible", this.state.phase != PHASE.QUESTION || this.state.hints.every(e => e.visible) || this.state.buzzed);
            this.nextHintButtonAfter.classed("invisible", !(this.state.phase == PHASE.QUESTION || this.state.phase == PHASE.RESULT) || this.state.hints.every(e => e.visible) || !this.state.buzzed);
            this.showResultButton.classed("invisible", this.state.phase != PHASE.RESULT || this.state.result_visible);
            this.wrongAnswerButton.classed("invisible", !this.state.buzzed);
        }
    }
}

/**
 * @private
 */
const HTML_SUBQUESTION_CLUE = `
<div id="subquestion_clue">
    <div id="subquestion_clue_content_outer">
        <div id="subquestion_clue_content_inner">
            <div id="subquestion_clue_hints"></div>
            <div id="subquestion_clue_result">
                <div>
                    <span></span>
                </div>
            </div>
        </div>
        <div class="counter"></div>
    </div>
    <div id="subquestion_clue_controls" class="hidden">
    </div>
</div>
`;