
/**
 * @typedef State
 * @property {number} time_full in seconds
 * @property {number} time_current in seconds
 * @property {Object[]} interims
 * @property {number} interims[].time in seconds
 * @property {string} [interims[].name]
 * @property {boolean} paused 
 */

/**
 * @typedef StateInfo
 * @property {ROLE} role
 */

/**
 * @enum {number}
 */
export const ROLE = {
    /**
     * - See everything.
     * - Edit everything.
     */
    ADMIN: 1,
    /**
     * - See everything.
     * - Edit nothing.
     */
    NOADMIN: 2
}

/**
 * @enum {number}
 */
export const MESSAGE = {
    /**
     * Starts the timer.
     * Only {@link ROLE.ADMIN}.
     */
    PLAY: 1,
    /**
     * Pauses the timer.
     * Only {@link ROLE.ADMIN}.
     */
    PAUSE: 2,
    /**
     * Resets the timer.
     * Only {@link ROLE.ADMIN}.
     */
    RESET: 3
}

/**
 * @type {string}
 */
export const CLASSID = "timer";