
export const SETTINGS_GAME = {
    DEFAULT_TEAM_COUNT: 3,
    PING_RATE: 10000
};

/**
 * @typedef State_Game
 * @property {State_Game_Player[]} players
 * @property {number[]} team_ids
 * 
 * @typedef State_Game_Player
 * @property {number} player_id
 * @property {string} name
 * @property {number} team_id
 * @property {boolean} admin
 * @property {boolean} moderator
 */

/**
 * @typedef StateInfo_Game
 * @property {ROLE_GAME} role
 */

/**
 * @enum {number}
 */
export const ROLE_GAME = {
    ADMIN: 1,
    NOADMIN: 2
};

/**
 * Possible messages to server. 
 * @enum {number}
 */
export const MESSAGE_GAME_TO = {
    /**
     * (Un-)sets a players admin rights. 
     * Only {@link ROLE_GAME.ADMIN}. 
     * @param {number} player_id
     * @param {boolean} admin
     */
    SET_ADMIN: 1,
    /**
     * (Un-)sets a players moderator rights. 
     * Only {@link ROLE_GAME.ADMIN}. 
     * @param {number} player_id
     * @param {boolean} moderator
     */
    SET_MODERATOR: 2,
    /**
     * Sets the Team of a player. 
     * Only {@link ROLE_GAME.ADMIN}. 
     * @param {number} player_id
     * @param {number} team_id smaller than 0 for no team
     */
    SET_TEAM: 3,
    /**
     * Reloads the GUI for a player. 
     * Only {@link ROLE_GAME.ADMIN} or for own player_id. 
     * @param {number} [player_id] undefined if self
     */
    RELOAD: 4,
    /**
     * Kicks a player. 
     * Only {@link ROLE_GAME.ADMIN}. 
     * @param {number} player_id
     */
    KICK: 5,
    /**
     * Sends a ping. Requests an answer. 
     */
    PING: 6
}

/**
 * Possible messages from server. 
 * @enum {number}
 */
export const MESSAGE_GAME_FROM = {
    /**
     * Sends a ping. Answers a ping. 
     */
    PING: 1
}


export const CLASSID_GAME = "game";
