import { GUIObject } from "../guiObject.js";
import * as d3 from "d3";

export class QuestionSelectionPairs extends GUIObject {

    constructor(guiManager, message) {
        super(guiManager, message);

        d3.select("#quiz_content").html(HTML);

        this.questionOverview = d3.select("#questionOverview");
        this.questions = this.questionOverview.select("#questions");

        this.questionsById = new Map();

        this.questions.classed("selectableHints", message.team && message.team.hints > 0);
        this.questions.classed("selectable", message.team);

        for (let question of message.all.questions) {
            let questionDiv = this.questions.append("div")
                .attr("class", "question");

            this.questionsById.set(question.id, questionDiv);

            let type = questionDiv.append("div")
                .attr("class", "question_type contentBox");
            if (question.type) {
                type.classed(question.type, true);
            }
            let name = questionDiv.append("div")
                .attr("class", "question_name contentBox");
            name.append("div")
                .html(question.name);
            let category = questionDiv.append("div")
                .attr("class", "question_category contentBox");
            if (question.category) {
                category.classed(question.category, true);
            }

            if (message.team && message.team.hints > 0) {
                type.on("click", function() {
                    if (!this.questions.classed("selectableHints")) {
                        return;
                    }
                    let message = {
                        type: "get_hint_type",
                        id: question.id
                    };
                    this.sendMessage(message);
                }.bind(this));
                category.on("click", function() {
                    if (!this.questions.classed("selectableHints")) {
                        return;
                    }
                    let message = {
                        type: "get_hint_category",
                        id: question.id
                    };
                    this.sendMessage(message);
                }.bind(this));
            }
            if (message.team) {
                name.on("click", function() {
                    if (!this.questions.classed("selectable")) {
                        return;
                    }
                    let message = {
                        type: "select_question",
                        id: question.id
                    };
                    this.sendMessage(message);
                }.bind(this));
            }
        }
        
        
        if (message.admin) {

        }
    }

    destroy() {
        super.destroy();
        this.questionOverview.remove();
    }

    handleMessage(message) {
        switch (message.all.type) {
            case "set_hint_type":
                this.questions.classed("selectableHints", false);
                this.questionsById.get(message.all.id).select(".question_type").classed(message.all.type_name, true);
                break;
            case "set_hint_category":
                this.questions.classed("selectableHints", false);
                this.questionsById.get(message.all.id).select(".question_category").classed(message.all.category, true);
                break;
            case "question_selected":
                this.questions.classed("selectableHints", false);
                this.questions.classed("selectable", false);
                this.questionsById.get(message.all.id).select(".question_name").classed("selected", true);
                break;
            default:
                break;
        }
    }
}

const HTML = `
    <div id="questionOverview">
        <div id="questiontype">
            Art
        </div>
        <div id="questions">
        </div>
        <div id="questioncategory">
            Kategorie
        </div>
    </div>
`;