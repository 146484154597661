import { GUIObject } from "../guiObject.js";
import * as d3 from "d3"

/**
 * @typedef State_Voting_Buzzer
 * @property {PHASE} phase
 * @property {Object[]} teams
 * @property {number} teams[].id
 * @property {boolean} teams[].voted
 */

/**
 * @enum {number}
 */
const PHASE = {
    /**
     * The teams can vote.
     */
    VOTING: 1,
    /**
     * Show the result to all.
     */
    RESULT: 2
}

/**
 * @enum {number}
 */
const ROLE = {
    /**
     * - See everything.
     * - Edit everything.
     */
    ADMIN: 1,
    /**
     * - See everything in {@link PHASE.RESULT} otherwise nothing.
     * - Edit nothing.
     */
    SPECTATOR: 2,
    /**
     * - See the own team decision and everthing in {@link PHASE.RESULT}.
     * - Edit own team decision.
     */
    TEAM: 3
}

/**
 * @enum {string}
 */
const MESSAGE_TYPE = {
    /**
     * Sets the voting of a team.
     * Only {@link ROLE.TEAM} for the own team.
     */
    VOTE: 1,
    /**
     * Unsets the voting of a team.
     * Only {@link ROLE.TEAM} for the own team.
     */
    UNVOTE: 2
}

/**
 * classid "voting_buzzer"
 * @extends GUIObject<State_Voting_Buzzer>
 */
export class Voting_Buzzer extends GUIObject {

    /**
     * @type {d3.Selection}
     */
    votingDivs;


    constructor(guiManager, message) {
        super(guiManager, message);

        this.votingDivs = d3.selectAll(".scoreboard_team").filter(d => this.state.teams.some(t => t.id == d.id)).select(".scoreboard_team_outerContent").append("div")
            .attr("class", "voting_buzzer");

        if (this.stateInfo.role == ROLE.TEAM && this.state.phase == PHASE.VOTING) {
            this.votingDivs.filter(d => d.id == this.stateInfo.team_id)
                .classed("ownTeam", true)
                .on("click", () => {
                    if (this.state.teams.find(t => t.id == this.stateInfo.team_id).voted) {
                        this.sendMessage({
                            type: MESSAGE_TYPE.UNVOTE
                        });
                    } else {
                        this.sendMessage({
                            type: MESSAGE_TYPE.VOTE
                        });
                    }
                });
            d3.select("body").on("keydown", e => {
                if (e.code != "Space") return;
                this.sendMessage({
                    type: MESSAGE_TYPE.VOTE
                });
            });
        }

        this.update();
    }

    destroy() {
        this.votingDivs.remove();
        if (this.stateInfo.role == ROLE.TEAM) {
            d3.select("body").on("keydown", null);
        }
    }

    update() {
        this.votingDivs.classed("voted", d => this.state.teams.find(t => t.id == d.id).voted)
            .classed("not_voted", d => this.state.teams.find(t => t.id == d.id).voted == false);
    }
}