import { PhaseButton } from "../elements/phaseButton.js";
import { GUIObject } from "../guiObject.js";
import { Question } from "./question.js";
import * as d3 from "d3"

/**
 * @enum {number}
 */
const PHASE = {
    /**
     * Only question text is visible.
     */
    STARTING: 1,
    /**
     * Admin can show categories and properties.
     */
    QUESTION: 2,
    /**
     * Teams can discuss and make notes.
     */
    PREPARATION: 3,
    /**
     * End of the preparation phase, switched to automatically after the timer expires.
     */
    PREPARATION_END: 4,
    /**
     * Teams give answers.
     */
    ASSIGN: 5,
    /**
     * Admin can show not given answers.
     */
    RESULTS: 6,
    /**
     * Teams get their points.
     */
    FINISHED: 7
}

const PHASE_NAMES = new Map([
    [1, "Start"],
    [2, "Frage"],
    [3, "Vorbereitung"],
    [4, "Vorbereitung Ende"],
    [5, "Zuordnen"],
    [6, "Ergebnis"],
    [7, "Ende"]
]);

/**
 * @enum {number}
 */
const ROLE = {
    ADMIN: 1,
    SPECTATOR: 2,
    TEAM: 3
}

/**
 * @enum {number}
 */
const NOTE = {
    UNDEFINED: 0,
    RIGHT: 1,
    WRONG: 2,
    UNCERTAIN: 3
}

/**
 * @enum {() => Object}
 */
const MESSAGE_TYPE = {

    /**
     * Transition to next {@link PHASE}.
     * Only {@link ROLE.ADMIN}.
     * Not in {@link PHASE.FINISHED}
     */
    NEXT_PHASE: () => { return {
        type: 1
    }},

    /**
     * Gives an answer.
     * Only {@link ROLE.ADMIN} or {@link ROLE.TEAM}.
     * Only in {@link PHASE.ASSIGN}.
     * @param {number} category_id
     * @param {number} property_id
     */
    ANSWER: (category_id, property_id) => { return {
        type: 2,
        category_id: category_id,
        property_id: property_id
    }},

    /**
     * Reverts the answer.
     * Only {@link ROLE.ADMIN}.
     * Only in {@link PHASE.ASSIGN}.
     */
    REVERT_ANSWER: () => { return {
        type: 3
    }},
    
    /**
     * Evaluates the answer.
     * Only {@link ROLE.ADMIN}.
     * Only in {@link PHASE.ASSIGN}.
     */
    EVALUATE_ANSWER: () => { return {
        type: 4
    }},

    /**
     * Assigns a property to its category.
     * Only {@link ROLE.ADMIN}.
     * Only in {@link PHASE.RESULTS}.
     * @param {number} property_id
     */
    ASSIGN: (property_id) => { return {
        type: 5,
        property_id: property_id
    }},

    /**
     * Changes the note of a team.
     * Only {@link ROLE.TEAM}.
     * Only in {@link PHASE.PREPARATION}, {@link PHASE.PREPARATION_END} or {@link PHASE.ASSIGN}.
     * @param {number} property_id
     * @param {number} category_id
     * @param {NOTE} note
     */
    CHANGE_NOTE: (category_id, property_id, note) => { return {
        type: 6,
        category_id: category_id,
        property_id: property_id,
        note: note
    }},

    /**
     * Reveals a category.
     * Only {@link ROLE.ADMIN}.
     * Only in {@link PHASE.QUESTION}.
     * @param {number} category_id
     */
    REVEAL_CATEGORY: (category_id) => { return {
        type: 7,
        category_id: category_id
    }},

    /**
     * Reveals the categories.
     * Only {@link ROLE.ADMIN}.
     * Only in {@link PHASE.QUESTION}.
     */
    REVEAL_CATEGORIES: () => { return {
        type: 8
    }},

    /**
     * Reveals a property.
     * Only {@link ROLE.ADMIN}.
     * Only in {@link PHASE.QUESTION}.
     * @param {number} property_id
     */
    REVEAL_PROPERTY: (property_id) => { return {
        type: 9,
        property_id: property_id
    }},

    /**
     * Reveals the properties.
     * Only {@link ROLE.ADMIN}.
     * Only in {@link PHASE.QUESTION}.
     */
    REVEAL_PROPERTIES: () => { return {
        type: 10
    }},

    /**
     * Adds a mistake to the current team and reverts their answer.
     * Only {@link ROLE.ADMIN}.
     * Only in {@link PHASE.ASSIGN}.
     */
    ANSWER_TO_LATE: () => { return {
        type: 11
    }}
}

/**
 * @typedef State_Question_Assign
 * @property {PHASE} phase
 * @property {number} max_category_text_width
 * @property {number} max_property_text_width
 * @property {State_Question_Assign_Category[]} categories
 * @property {State_Question_Assign_Property[]} properties
 * 
 * @typedef State_Question_Assign_Category
 * @property {number} id set in constructor
 * @property {string} text
 * @property {string} image_url
 * @property {boolean} visible
 * @property {number[]} properties[]
 * 
 * @typedef State_Question_Assign_Property
 * @property {number} id set in constructor
 * @property {string} text
 * @property {string} image_url
 * @property {boolean} visible
 * @property {boolean} revealed
 * @property {number} category Only visible for {@link ROLE.ADMIN} or if already revealed.
 * @property {State_Question_Assign_Team_Note[]} team_notes Only visible for {@link ROLE.ADMIN} or the corresponding {@link ROLE.TEAM}.
 * 
 * @typedef State_Question_Assign_Team_Note
 * @property {number} property_id set in constructor
 * @property {number} team_id
 * @property {NOTE[]} notes
 */

/**
 * @extends Question<State_Question_Assign>
 */
export class Question_Assign extends Question {

    /**
     * Id of the property where the next note will be assign to.
     * @type {number}
     */
    chosenPropertyId;

    /**
     * @type {PhaseButton}
     */
    phaseButton;

    /**
     * @type {d3.Selection}
     */
    button_revealCategories;

    /**
     * @type {d3.Selection}
     */
    button_revealProperties;

    /**
     * @type {d3.Selection}
     */
    button_evaluateAnswer;

    /**
     * @type {d3.Selection}
     */
    button_answerToLate;

    /**
     * @param {GUIObject} guiManager 
     * @param {Object} message 
     */
    constructor(guiManager, message) {
        super(guiManager, message);

        this.chosenPropertyId = -1;

        // Changes which are stable with state patches
        this.state.categories.forEach((c, i) => c.id = i);
        this.state.properties.forEach((p, i) => p.id = i);
        this.state.properties.forEach(p => p.team_notes.forEach(tn => tn.property_id = p.id));

        d3.select("#question_content").html(HTML);
        this.assignDiv = d3.select("#question_assign");

        // create control area for admins
        if (this.stateInfo.role == ROLE.ADMIN) {
            let controlDiv = d3.select("#question_assign_controls").classed("hidden", false);
            this.phaseButton = new PhaseButton(this, controlDiv, PHASE_NAMES, this.state.phase, MESSAGE_TYPE.NEXT_PHASE);
            this.button_revealCategories = controlDiv.append("div")
                .attr("class", "standardButton hidden")
                .text("Zeige Kategorien")
                .on("click", () => this.sendMessage(MESSAGE_TYPE.REVEAL_CATEGORIES()));
            this.button_revealProperties = controlDiv.append("div")
                .attr("class", "standardButton hidden")
                .text("Zeige Elemente")
                .on("click", () => this.sendMessage(MESSAGE_TYPE.REVEAL_PROPERTIES()));
            this.button_evaluateAnswer = controlDiv.append("div")
                .attr("class", "standardButton hidden")
                .text("Antwort auswerten")
                .on("click", () => this.sendMessage(MESSAGE_TYPE.EVALUATE_ANSWER()));
            this.button_answerToLate = controlDiv.append("div")
                .attr("class", "standardButton hidden")
                .text("Antwort zu spät")
                .on("click", () => this.sendMessage(MESSAGE_TYPE.ANSWER_TO_LATE()));
        }

        this.update();
    }

    destroy() {
        this.assignDiv.remove();
        super.destroy();
    }

    update() {
        if (this.phaseButton) {
            this.phaseButton.setPhase(this.state.phase);
        }

        let property_width = Math.min(Math.max(this.state.max_property_text_width, this.state.max_category_text_width - 1), 15);
        let category_width = property_width + 1;
        let property_height = Math.ceil(this.state.max_property_text_width / 15) * 1.2;
        let category_height = Math.ceil(this.state.max_category_text_width / 16) * 1.2;
        

        this.startPosition(this.state.phase == PHASE.STARTING);
        this.assignDiv.select("#question_assign_content").classed("invisible", this.state.phase == PHASE.STARTING);

        let categoryJoin = this.assignDiv.select("#question_assign_categories").selectAll(".categoryDiv")
            .data(this.state.categories, (_, i) => i)
            .join(enter => enter.append("div")
                .attr("class", "categoryDiv")
                .call(div => div.append("div")
                    .attr("class", "category smallContentBox")
                    .style("width", category_width + "em")
                    .style("height", category_height + "em")
                    .call(category => category.append("div")
                        .attr("class", "category_selectors hidden"))
                    .call(category => category.append("div")
                        .attr("class", "category_name"))
                    .call(category => category.append("div")
                        .attr("class", "category_image")
                        .append("img")))
                .call(div => div.append("div")
                    .attr("class", "category_properties"))
            )
            .call(div => div.select(".category")
                .classed("halfvisible", d => !d.visible && this.stateInfo.role == ROLE.ADMIN)
                .classed("rotateOut", d => !d.visible && this.stateInfo.role != ROLE.ADMIN)
                .call(UPDATE_CATEGORY_DATA));

        categoryJoin.select(".category_selectors").selectAll(".category_note_selector")
            .data(d => Array(NOTE.RIGHT, NOTE.WRONG, NOTE.UNCERTAIN).map(note => {return {
                category_id: d.id,
                note: note
            }}))
            .enter()
            .append("div")
                .attr("class", d =>
                    d.note == NOTE.RIGHT ? "category_note_selector note_right" :
                    d.note == NOTE.WRONG ? "category_note_selector note_wrong" : 
                    d.note == NOTE.UNCERTAIN ? "category_note_selector note_uncertain" : "")
                .on("click", (_, d) => {
                    if (this.chosenPropertyId < 0) return;
                    this.sendMessage(MESSAGE_TYPE.CHANGE_NOTE(d.category_id, this.chosenPropertyId, d.note));
                    propertyJoin.classed("selected", false);
                    categoryJoin.select(".category").call(RESET_NOTE_CLASSES);
                    categoryJoin.select(".category_selectors")
                        .classed("hidden", true);
                    this.chosenPropertyId = -1;
                });
        
        let answer = -1;

        let categoryPropertyJoin = categoryJoin.select(".category_properties").selectAll(".property")
            .data(d => d.properties.map(p => this.state.properties[p]), d => d.id)
            .join(
                enter => enter.append("div")
                    .attr("class", "property smallContentBox")
                    .style("width", property_width + "em")
                    .style("height", property_height + "em")
                    .call(div => div.append("div")
                        .attr("class", "property_name"))
                    .call(div => div.append("div")
                        .attr("class", "property_image")
                        .append("img")),
                update => update,
                exit => exit.classed("wrong", true)
                    .on("transitionend", /** @param {TransitionEvent} e */ e => {
                        if (e.propertyName != "opacity") return;
                        d3.select(e.currentTarget).remove()
                    })
            )
            .call(selection => selection.filter(".answer").filter(d => d.revealed)
                .classed("right", true)
                .on("transitionend", /** @param {TransitionEvent} e */ e => d3.select(e.currentTarget)
                    .on("transitionend", null)
                    .call(selection => setTimeout(() => selection.classed("right", false), 3000))))
            .classed("answer", d => !d.revealed)
            .each(d => {
                if (!d.revealed) answer = d.id
            })
            .call(UPDATE_PROPERTY_DATA);

        let propertyJoin = this.assignDiv.select("#question_assign_properties").selectAll(".property")
            .data(this.state.properties, (_, i) => i)
            .join(enter => enter.append("div")
                .attr("class", "property smallContentBox")
                .style("width", property_width + "em")
                .style("height", property_height + "em")
                .call(div => div.append("div")
                    .attr("class", "property_team_notes"))
                .call(div => div.append("div")
                    .attr("class", "property_name"))
                .call(div => div.append("div")
                    .attr("class", "property_image")
                    .append("img"))
                .call(selection => {if (this.stateInfo.role == ROLE.TEAM) selection
                    .on("mouseenter", (_, d) => {
                        if (this.chosenPropertyId >= 0) return;
                        categoryJoin.select(".category").call(SET_NOTE_CATEGORY, d.team_notes[0].notes);
                    })
                    .on("mouseleave", () => {
                        if (this.chosenPropertyId >= 0) return;
                        categoryJoin.select(".category").call(RESET_NOTE_CLASSES);
                    })
                    .on("click", (_, d) => {
                        this.chosenPropertyId = d.id;
                        propertyJoin.classed("selected", pd => pd.id == this.chosenPropertyId);
                        categoryJoin.select(".category").call(SET_NOTE_CATEGORY, d.team_notes[0].notes);
                        categoryJoin.select(".category_selectors").classed("hidden", false);
                    })})
            )
            .classed("halfvisible", d => !d.visible && this.stateInfo.role == ROLE.ADMIN)
            .classed("rotateOut", d => !d.visible && this.stateInfo.role != ROLE.ADMIN)
            .call(selection => selection.filter(".assigned").filter(d => !(d.revealed || d.id == answer))
                .call(selection => setTimeout(() => selection.classed("assigned", false), 3500)))
            .call(selection => selection.filter(d => d.revealed || d.id == answer).classed("assigned", true))
            .call(UPDATE_PROPERTY_DATA);
            

        let propertyTeamJoin = propertyJoin.select(".property_team_notes").selectAll(".team_notes")
            .data(d => d.team_notes, d => d.team_id)
            .join(enter => enter.append("div")
                .attr("class", "team_notes")
                .call(selection => {if (this.stateInfo.role != ROLE.TEAM) selection
                    .on("mouseenter", (_, d) => {
                        categoryJoin.select(".category").call(SET_NOTE_CATEGORY, d.notes);
                    })
                    .on("mouseleave", () => {
                        categoryJoin.select(".category").call(RESET_NOTE_CLASSES);
                    })}));

        let propertyTeamNotesJoin = propertyTeamJoin.selectAll(".team_note")
            .data(d => d.notes, (_, i) => i)
            .join(enter => enter.append("div")
                .attr("class", "team_note"))
            .call(SET_CLASS_NOTE);
        
        switch (this.state.phase) {
            case PHASE.ASSIGN:
                propertyJoin.attr("draggable", true)
                    .on("dragstart", /** @param {DragEvent} e */ (e, d) => {
                        e.dataTransfer.setData("text/plain", d.id);
                    });
                categoryJoin
                    .on("dragover", /** @param {DragEvent} e */ e => {
                        e.preventDefault();
                        e.dataTransfer.dropEffect = "move";
                    })
                    .on("dragenter", /** @param {DragEvent} e */ e => {
                        d3.select(e.currentTarget).classed("drop_selected", true);
                    })
                    .on("dragleave", /** @param {DragEvent} e */ e => {
                        let target = d3.select(e.currentTarget);
                        let rect = target.node().getBoundingClientRect();
                        if (e.x < rect.x || e.x > rect.x + rect.width || e.y < rect.y || e.y > rect.y + rect.height) {
                            target.classed("drop_selected", false);
                        }
                    })
                    .on("drop", /** @param {DragEvent} e */ (e, d) => {
                        e.preventDefault();
                        d3.select(e.currentTarget).classed("drop_selected", false);
                        this.sendMessage(MESSAGE_TYPE.ANSWER(d.id, parseInt(e.dataTransfer.getData("text/plain"))));
                    });
                break;
            default:
                propertyJoin.attr("draggable", false)
                    .on("dragstart", null);
                categoryJoin
                    .on("dragover", null)
                    .on("dragenter", null)
                    .on("dragleave", null)
                    .on("drop", null);
                break;
        }          

        if (this.stateInfo.role == ROLE.ADMIN) {

            if (this.state.phase == PHASE.QUESTION) {
                this.button_revealCategories.classed("hidden", false);
                this.button_revealProperties.classed("hidden", false);
            } else {
                this.button_revealCategories.classed("hidden", true);
                this.button_revealProperties.classed("hidden", true);
            }

            if (this.state.phase == PHASE.ASSIGN) {
                this.button_evaluateAnswer.classed("hidden", false);
                this.button_answerToLate.classed("hidden", false);
            } else {
                this.button_evaluateAnswer.classed("hidden", true);
                this.button_answerToLate.classed("hidden", true);
            }

            propertyJoin
                .on("mouseenter", (_, d) => {
                    categoryJoin.select(".category").classed("matching", cd => cd.id == d.category);
                })
                .on("mouseleave", () => {
                    categoryJoin.select(".category").classed("matching", false);
                });
            
            if (this.state.phase == PHASE.QUESTION) {
                categoryJoin.select(".category").on("click", (_, d) => {
                    if (d.visible) return;
                    this.sendMessage(MESSAGE_TYPE.REVEAL_CATEGORY(d.id));
                });
            } else {
                categoryJoin.select(".category").on("click", null);
            }

            if (this.state.phase == PHASE.QUESTION) {
                propertyJoin.on("click", (_, d) => {
                    if (d.visible) return;
                    this.sendMessage(MESSAGE_TYPE.REVEAL_PROPERTY(d.id));
                });
            } else if (this.state.phase == PHASE.RESULTS)
                propertyJoin.on("click", (_, d) => {
                    this.sendMessage(MESSAGE_TYPE.ASSIGN(d.id));
                });
            else {
                propertyJoin.on("click", null);
            }

        } else if (this.stateInfo.role == ROLE.TEAM) {

            categoryJoin.select(".category")
                .on("mouseenter", (_, d) => {
                    if (this.chosenPropertyId >= 0) return;
                    propertyJoin.call(SET_NOTE_PROPERTY, d.id);
                })
                .on("mouseleave", () => {
                    if (this.chosenPropertyId >= 0) return;
                    propertyJoin.call(RESET_NOTE_CLASSES);
                });

            if (this.state.phase == PHASE.ASSIGN) {
                categoryJoin.select(".category")
                    .on("click", (_, d) => {
                        if (this.chosenPropertyId < 0) return;
                        this.sendMessage(MESSAGE_TYPE.ANSWER(d.id, this.chosenPropertyId));
                        propertyJoin.classed("selected", false);
                        categoryJoin.select(".category").call(RESET_NOTE_CLASSES);
                        categoryJoin.select(".category_selectors")
                            .classed("hidden", true);
                        this.chosenPropertyId = -1;
                    });
            } else {
                categoryJoin.select(".category")
                    .on("click", null);
            }
        }
    }
}

/**
 * @param {d3.Selection<BaseType|HTMLDivElement,State_Question_Assign_Category,BaseType,any>} selection
 */
const UPDATE_CATEGORY_DATA = selection => {
    selection.select(".category_name")
        .text(d => d.text);
    selection.select(".category_image img")
        .attr("src", d => d.image_url);
}

/**
 * @param {d3.Selection<BaseType|HTMLDivElement,State_Question_Assign_Property,BaseType,any>} selection
 */
const UPDATE_PROPERTY_DATA = selection => {
    selection.select(".property_name")
        .text(d => d.text);
    selection.select(".property_image img")
        .attr("src", d => d.image_url);
}

/**
 * @param {d3.Selection<BaseType|HTMLDivElement,NOTE,BaseType,any>} selection
 * @param {NOTE} note
 */
const SET_CLASS_NOTE = selection => {
    selection
        .classed("note_right", d => d == NOTE.RIGHT)
        .classed("note_wrong", d => d == NOTE.WRONG)
        .classed("note_uncertain", d => d == NOTE.UNCERTAIN);
}

/**
 * @param {d3.Selection<BaseType|HTMLDivElement,State_Question_Assign_Property,BaseType,any>} selection
 * @param {NOTE[]} notes
 */
const SET_NOTE_PROPERTY = (selection, categoryId) => {
    selection
        .classed("note_right", d => d.team_notes[0].notes[categoryId] == NOTE.RIGHT)
        .classed("note_wrong", d => d.team_notes[0].notes[categoryId] == NOTE.WRONG)
        .classed("note_uncertain", d => d.team_notes[0].notes[categoryId] == NOTE.UNCERTAIN);
}

/**
 * @param {d3.Selection<BaseType|HTMLDivElement,State_Question_Assign_Category,BaseType,any>} selection
 * @param {NOTE[]} notes
 */
const SET_NOTE_CATEGORY = (selection, notes) => {
    selection
        .classed("note_right", d => notes[d.id] == NOTE.RIGHT)
        .classed("note_wrong", d => notes[d.id] == NOTE.WRONG)
        .classed("note_uncertain", d => notes[d.id] == NOTE.UNCERTAIN);
}

/**
 * @param {d3.Selection} selection
 */
const RESET_NOTE_CLASSES = selection => {
    selection
        .classed("note_right", false)
        .classed("note_wrong", false)
        .classed("note_uncertain", false);
}

const HTML = `
<div id="question_assign">
    <div id="question_assign_content">
        <div id="question_assign_categories"></div>
        <div id="question_assign_properties"></div>
    </div>
    <div id="question_assign_controls" class="hidden">
    </div>
</div>
`