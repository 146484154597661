
import * as language from "../lang/de.json";


/**
 * @param {string} key 
 * @return {string}
 */
export function getLanguageText(key) {
    let text = language[key];
    if (!text) return key;
    return text;
}