/**
 * TODOs
 * left right arrow for fullscreen images
 * fullscreen image controls for moderators
 */


import { MESSAGE_QUESTION_SORT, PHASE_QUESTION_SORT, ROLE_QUESTION_SORT, SETTINGS_QUESTION_SORT, TAG_QUESTION_SORT } from "../definitions/questions/sort.defs.js";
import { addEdges } from "../elements/edges.js";
import { createInfoDiv, updateInfoDiv } from "../elements/hoverInfo.js";
import { PhaseButton } from "../elements/phaseButton.js";
import { TeamViewSelectorWithGeneralView } from "../elements/teamViewSelector.js";
import { Question } from "./question.js";
import * as d3 from "d3";

import "../../styles/questions/sort.css";

/**
 * @typedef {d3.Selection<BaseType,import("../definitions/questions/sort.defs.js").State_Question_Sort_Element,BaseType,any>} ElementSelection
 */

/**
 * @typedef {d3.Selection<BaseType,import("../definitions/questions/sort.defs.js").State_Question_Sort_Element|import("../definitions/questions/sort.defs.js").State_Question_Sort_Note,BaseType,any>} ElementWithNoteSelection
 */

const HTML = `
<div id="question_sort">
    <div id="question_sort_content">
        <img id="fullscreen_image" width=0 height=0>
        <div id="question_sort_column_left">
            <div class="top_limit limit hidden"></div>
            <div id="question_sort_answers_outer">
                <div id="question_sort_answers_inner"></div>
                <div class="counter"></div>
            </div>
            <div class="bottom_limit limit hidden"></div>
        </div>
        <div id="question_sort_column_right">
            <div id="question_sort_elements_outer" class="hidden">
                <div id="question_sort_elements_inner"></div>
            </div>
        </div>
    </div>
    <div id="question_sort_controls" class="hidden"></div>
</div>
`;

const TIME_ANSWER_VISIBLE = 5000;

const START_POSITION_FUNCTION = state => state.phase == PHASE_QUESTION_SORT.START;

/**
 * classid "question_sort"
 * @extends Question<import("../definitions/questions/sort.defs").State_Question_Sort,import("../definitions/questions/sort.defs").StateInfo_Question_Sort>
 */
export class Question_Sort extends Question {

    /**
     * @type {d3.Selection}
     */
    sortDiv;

    /**
     * @type {d3.Selection}
     */
    contentDiv;

    /**
     * @type {PhaseButton}
     */
    phaseButton;

    /**
     * @type {d3.Selection}
     */
    buttonShowNextElement;

    /**
     * @type {d3.Selection}
     */
    buttonShowElements;

    /**
     * @type {d3.Selection}
     */
    buttonEvaluateAnswer;

    /**
     * @type {d3.Selection}
     */
    buttonAnswerLate;

    /**
     * @type {d3.Selection}
     */
    buttonRevertAnswer;

    /**
     * @type {d3.Selection}
     */
    buttonOrderElements;

    /**
     * @type {d3.Selection}
     */
    buttonShowValues;

    /**
     * @type {d3.Selection}
     */
    buttonShowInfos;

    /**
     * id. -1, if no element is dragged
     * @type {number}
     */
    draggedElement;

    /**
     * @type {d3.Selection}
     */
    fullscreenImage;

    /**
     * @type {d3.Selection}
     */
    activeTeamMarkers;


    constructor(guiManager, message) {
        super(guiManager, message, START_POSITION_FUNCTION);
        
        this.draggedElement = -1;

        d3.select("#question_content").html(HTML);

        this.fullscreenImage = d3.select("#fullscreen_image");

        this.sortDiv = d3.select("#question_sort");
        this.contentDiv = this.sortDiv.select("#question_sort_content");
        
        if (this.stateInfo.role == ROLE_QUESTION_SORT.ADMIN) {
            let controls = this.sortDiv.select("#question_sort_controls")
                .classed("hidden", false);

            this.phaseButton = new PhaseButton(this, controls, new Map([
                [PHASE_QUESTION_SORT.START, "Start"],
                [PHASE_QUESTION_SORT.QUESTION, "Frage"],
                [PHASE_QUESTION_SORT.PREPARATION, "Vorbereitung"],
                [PHASE_QUESTION_SORT.PREPARATION_END, "Vorbereitung_Ende"],
                [PHASE_QUESTION_SORT.ANSWERS, "Antworten"],
                [PHASE_QUESTION_SORT.RESULTS, "Ergebnisse"],
                [PHASE_QUESTION_SORT.FINISHED, "Ende"],
            ]), this.state.phase, phase => ({
                type: MESSAGE_QUESTION_SORT.NEXT_PHASE,
                next_phase: phase+1
            }));

            this.buttonShowNextElement = controls.append("div")
                .attr("class", "hidden")
                .text("Zeige nächstes Element")
                .on("click", () => {
                    let element = this.state.elements.find(e => !e.visible && !e.position_visible);
                    if (!element) element = this.state.elements.find(e => !e.visible);
                    if (!element) return;
                    this.sendMessage({
                        type: MESSAGE_QUESTION_SORT.SHOW_ELEMENT,
                        element_id: element.id
                    });
                });
            this.buttonShowElements = controls.append("div")
                .attr("class", "hidden")
                .text("Zeige alle Elemente")
                .on("click", () => this.sendMessage({
                    type: MESSAGE_QUESTION_SORT.SHOW_ELEMENTS
                }));
            this.buttonEvaluateAnswer = controls.append("div")
                .attr("class", "hidden")
                .text("Antwort auswerten")
                .on("click", () => this.sendMessage({
                    type: MESSAGE_QUESTION_SORT.EVALUATE_ANSWER
                }));
            this.buttonAnswerLate = controls.append("div")
                .attr("class", "hidden")
                .text("Antwort zu spät")
                .on("click", () => this.sendMessage({
                    type: MESSAGE_QUESTION_SORT.ANSWER_TOO_LATE
                }));
            this.buttonRevertAnswer = controls.append("div")
                .attr("class", "hidden")
                .text("Antwort rückgängig")
                .on("click", () => this.sendMessage({
                    type: MESSAGE_QUESTION_SORT.REVERT_ANSWER
                }));
            this.buttonOrderElements = controls.append("div")
                .attr("class", "hidden")
                .text("Ordne Elemente")
                .on("click", () => this.sendMessage({
                    type: MESSAGE_QUESTION_SORT.ORDER_ELEMENTS
                }));
            this.buttonShowValues = controls.append("div")
                .attr("class", "hidden")
                .text("Zeige Werte")
                .on("click", () => this.sendMessage({
                    type: MESSAGE_QUESTION_SORT.SHOW_VALUES
                }));
            this.buttonShowInfos = controls.append("div")
                .attr("class", "hidden")
                .text("Zeige Infos")
                .on("click", () => this.sendMessage({
                    type: MESSAGE_QUESTION_SORT.SHOW_INFOS
                }));
        }

        this.sortDiv.select("#question_sort_notes_div")
            .classed("hidden", this.stateInfo.role != ROLE_QUESTION_SORT.TEAM);
        this.sortDiv.select("#question_sort_elements_outer")
            .classed("hidden", (this.stateInfo.role != ROLE_QUESTION_SORT.SPECTATOR && this.state.moderation_team_id >= 0 || this.stateInfo.role == ROLE_QUESTION_SORT.TEAM));

        if (this.stateInfo.role == ROLE_QUESTION_SORT.ADMIN) {
            this.teamViewSelector = new TeamViewSelectorWithGeneralView(
                id => this.sendMessage({
                    type: MESSAGE_QUESTION_SORT.MODERATED_TEAM,
                    team_id: id
                }),
                () => this.sendMessage({
                    type: MESSAGE_QUESTION_SORT.MODERATED_TEAM,
                    team_id: -1
                })
            );
        }

        if (this.stateInfo.role == ROLE_QUESTION_SORT.ADMIN || this.stateInfo.role == ROLE_QUESTION_SORT.MODERATOR) {
            this.activeTeamMarkers = d3.selectAll(".scoreboard_team").select(".scoreboard_team_outerContent").append("div")
                .attr("class", "active_team_marker")
                .classed("hidden", d => d.id != this.state.moderation_team_id);
        }
    }

    /**
     * @param {boolean} b 
     * @protected
     */
    startPosition(b) {
        super.startPosition(b);
        this.contentDiv.classed("invisible", b);
    }

    destroy() {
        if (this.teamViewSelector) this.teamViewSelector.destroy();
        if (this.activeTeamMarkers) this.activeTeamMarkers.remove();

        this.sortDiv.remove();

        super.destroy();
    }

    update() {
        super.update();
        
        // * Buttons
        if (this.stateInfo.role == ROLE_QUESTION_SORT.ADMIN) {
            this.phaseButton.setPhase(this.state.phase);
            this.buttonShowNextElement.classed("hidden", this.state.phase != PHASE_QUESTION_SORT.QUESTION || !this.state.elements.some(e => !e.visible));
            this.buttonShowElements.classed("hidden", this.state.phase != PHASE_QUESTION_SORT.QUESTION || !this.state.elements.some(e => !e.visible));
            this.buttonEvaluateAnswer.classed("hidden", this.state.phase != PHASE_QUESTION_SORT.ANSWERS || !this.state.answer);
            this.buttonAnswerLate.classed("hidden", this.state.phase != PHASE_QUESTION_SORT.ANSWERS || !this.state.answer);
            this.buttonRevertAnswer.classed("hidden", this.state.phase != PHASE_QUESTION_SORT.ANSWERS || !this.state.answer);
            this.buttonOrderElements.classed("hidden", this.state.phase != PHASE_QUESTION_SORT.RESULTS || this.state.elements.every(e => e.position_visible));
            this.buttonShowValues.classed("hidden", this.state.phase != PHASE_QUESTION_SORT.RESULTS || this.state.elements.every(e => e.value_visible));
            this.buttonShowInfos.classed("hidden", this.state.phase != PHASE_QUESTION_SORT.RESULTS || this.state.elements.every(e => e.info_result_visible));
        }


        this.updateAnswers();

        if (this.stateInfo.role != ROLE_QUESTION_SORT.TEAM) {
            this.updateElements();
        }

        if (this.stateInfo.role != ROLE_QUESTION_SORT.SPECTATOR) {
            this.updateNotes();
        }

        if (this.state.order_top) this.sortDiv.selectAll(".top_limit")
            .classed("hidden", false)
            .text(this.state.order_top);
        if (this.state.order_bottom) this.sortDiv.selectAll(".bottom_limit")
            .classed("hidden", false)
            .text(this.state.order_bottom);

        if (this.stateInfo.role == ROLE_QUESTION_SORT.ADMIN || this.stateInfo.role == ROLE_QUESTION_SORT.MODERATOR) {
            this.updateActiveTeamMarker();
        }
    }

    updateActiveTeamMarker() {
        if (this.state.moderation_team_id != this.oldState.moderation_team_id) {
            let oldMod = this.activeTeamMarkers.filter(d => d.id == this.oldState.moderation_team_id);
            oldMod.classed("fade_out_top", this.state.moderation_team_id < this.oldState.moderation_team_id)
                .classed("fade_out_bottom", this.state.moderation_team_id > this.oldState.moderation_team_id)
                .classed("fade_in_top", false)
                .classed("fade_in_bottom", false)
                .on("animationend", null)
                .on("transitionend", e => {
                    if (e.target != e.currentTarget) return;
                    oldMod.classed("fade_out_top", false)
                        .classed("fade_out_bottom", false)
                        .classed("hidden", true);
                });
            let newMod = this.activeTeamMarkers.filter(d => d.id == this.state.moderation_team_id);
            newMod.classed("hidden", false)
                .classed("fade_in_top", this.state.moderation_team_id > this.oldState.moderation_team_id)
                .classed("fade_in_bottom", this.state.moderation_team_id < this.oldState.moderation_team_id)
                .classed("fade_out_top", false)
                .classed("fade_out_bottom", false)
                .on("transitionend", null)
                .on("animationend", e => {
                    if (e.target != e.currentTarget) return;
                    newMod.classed("fade_in_top", false)
                        .classed("fade_in_bottom", false);
                });
        }
    }

    /**
     * * ANSWERS
     */

    updateAnswers() {

        this.sortDiv.select("#question_sort_column_left")
            .classed("fade_out", this.stateInfo.role == ROLE_QUESTION_SORT.TEAM && this.state.phase <= PHASE_QUESTION_SORT.PREPARATION);

        let answerData = this.state.order.map(id => this.state.elements.find(e => e.id == id));
        if (this.stateInfo.role == ROLE_QUESTION_SORT.ADMIN) answerData = answerData.filter(e => e.position_visible);
        if (this.state.answer) answerData.splice(this.state.answer.position, 0, this.state.elements.find(e => e.id == this.state.answer.element_id));

        let answerElements = this.sortDiv.select("#question_sort_answers_inner").selectAll(".element_state")
            .data(answerData, d => d.id)
            .join(
                this.answersEnter.bind(this),
                update => update,
                this.answersExit.bind(this)
            )
            .call(this.answerUpdate.bind(this));
        
        let answerDataGap = Array(answerData.length+1).fill(0).map((_, i) => ({
            position: i,
            previous: i > 0 ? answerData[i-1].id : -1,
            next: i < answerData.length ? answerData[i].id : -1
        }));

        let answerGapElements = this.sortDiv.select("#question_sort_answers_inner").selectAll(".element_gap")
            .data(answerDataGap, d => JSON.stringify(d))
            .join(
                this.answerGapEnter.bind(this),
                update => update,
                this.answerGapExit.bind(this)
            )
            .call(this.answerGapUpdate.bind(this));
    }

    /**
     * @param {ElementSelection} enter 
     * @returns {ElementSelection}
     */
    answersEnter(enter) {
        let element = enter.append("div")
            .attr("class", "element_state element")
            .classed("answer", d => this.state.answer && this.state.answer.element_id == d.id)
            .call(createInfoDiv);
        let inner = element.append("div")
            .attr("class", "element_inner");
        inner.append("div")
            .attr("class", "number")
            .call(div => div.append("span"))
            .call(addEdges, "span");
        inner.append("div")
            .attr("class", "name")
            .call(div => div.append("div")
                .attr("class", "name_wrapper")
                .call(div => div.append("span")
                    .style("width", "calc( " + this.state.elements_max_name_length + "em + 1px )"))
                .call(div => div.append("img")
                    .attr("sizes", "2.2em")
                    .on("click", (_, d) => {
                        this.fullscreenImage.attr("src", d.img_url);
                        this.fullscreenImage.node().requestFullscreen();
                    })
                )
            )
            .call(addEdges, ".name_wrapper", true)
            .call(div => {
                if (this.stateInfo.role != ROLE_QUESTION_SORT.ADMIN) return;
                div.append("div").attr("class", "button_show_element")
                    .on("click", (_, d) => this.sendMessage({
                        type: MESSAGE_QUESTION_SORT.SHOW_ELEMENT,
                        element_id: d.id
                    }));
            });
        inner.append("div")
            .attr("class", "value")
            .call(div => div.append("span")
                .style("width", "calc( " + this.state.elements_max_value_length + "em + 1px )"))
            .call(addEdges, "span", true)
            .call(div => {
                if (this.stateInfo.role != ROLE_QUESTION_SORT.ADMIN) return;
                div.append("div").attr("class", "button_show_value")
                    .on("click", (_, d) => this.sendMessage({
                        type: MESSAGE_QUESTION_SORT.SHOW_VALUE,
                        element_id: d.id
                    }));
            });
        return element;
    }

    /**
     * @param {ElementSelection} update 
     * @returns {ElementSelection}
     */
    answerUpdate(update) {
        update.call(updateInfoDiv, d => d.info);
        update.filter(d => this.oldState.answer && this.oldState.answer.element_id == d.id && (!this.state.answer || this.state.answer.element_id != d.id))
            .classed("answer", false)
            .classed("answer_correct", true)
            .call(div => setTimeout(() => {
                div.classed("answer_correct", false);
            }, TIME_ANSWER_VISIBLE));
        update.classed("fade_out", d => !d.visible && this.stateInfo.role != ROLE_QUESTION_SORT.ADMIN)
            .classed("halfvisible", d => !d.visible && this.stateInfo.role == ROLE_QUESTION_SORT.ADMIN);
        update.select(".number > span")
            .text((_, i) => (i+1) + ".");
        update.select(".name span")
            .classed("hidden", d => !d.name)
            .text(d => d.name);
        update.select(".name img")
            .classed("hidden", d => !d.img_url)
            .filter(d => d.img_url)
                .attr("src", d => {
                    let index = d.img_url.lastIndexOf(".");
                    return d.img_url.slice(0, index) + "_thumbnail" + d.img_url.slice(index);
                })
        update.select(".value")
            .classed("fade_out", d => !d.value_visible && this.stateInfo.role != ROLE_QUESTION_SORT.ADMIN)
            .classed("halfvisible", d => !d.value_visible && this.stateInfo.role == ROLE_QUESTION_SORT.ADMIN && d.value)
            .classed("hidden", d => this.stateInfo.role == ROLE_QUESTION_SORT.ADMIN && !d.value 
                || this.stateInfo.role != ROLE_QUESTION_SORT.ADMIN && d.value_visible && !d.value)
            .select("span")
                .text(d => d.value == undefined ? "" : d.value);

        update.select(".button_show_value")
            .classed("invisible", this.state.phase != PHASE_QUESTION_SORT.RESULTS);
        return update;
    }

    /**
     * @param {ElementSelection} exit 
     */
    answersExit(exit) {
        exit.classed("element_state", false)
            .classed("element_remove", true)
            .on("animationend", function() {
                d3.select(this).remove();
            });
    }


    /**
     * * ELEMENTS
     */

    updateElements() {
        let elementData = this.state.elements.filter(e => !e.position_visible);
        let elementDiv = this.sortDiv.select("#question_sort_elements_outer")
        if (elementData.length == 0) {
            elementDiv.classed("hidden", elementData.length == 0);
        }
        let elementElements = this.sortDiv.select("#question_sort_elements_inner").selectAll(".element_state")
            .data(elementData, d => d.id)
            .join(
                this.elementEnter.bind(this),
                update => update,
                this.elementExit.bind(this)
            )
            .call(this.elementUpdate.bind(this));

        if (this.stateInfo.role != ROLE_QUESTION_SORT.SPECTATOR && this.state.moderation_team_id != this.oldState.moderation_team_id) {
            if (this.state.moderation_team_id >= 0 && this.oldState.moderation_team_id < 0) {
                elementDiv.classed("fade_out_top", true)
                    .classed("fade_in_top", false)
                    .on("animationend", null)
                    .on("transitionend", e => {
                        if (e.target != e.currentTarget) return;
                        elementDiv.classed("fade_out_top", false)
                            .classed("hidden", true);
                    });
            } else if (this.state.moderation_team_id < 0 && this.oldState.moderation_team_id >= 0) {
                elementDiv.classed("hidden", false)
                    .classed("fade_in_top", true)
                    .classed("fade_out_top", false)
                    .on("transitionend", null)
                    .on("animationend", e => {
                        if (e.target != e.currentTarget) return;
                        elementDiv.classed("fade_in_top", false);
                    });
            }
        }
    }

    /**
     * @param {ElementSelection} enter 
     * @returns {ElementSelection}
     */
    elementEnter(enter) {
        let element = enter.append("div")
            .attr("class", "element element_state")
            .call(createInfoDiv);
        let inner = element.append("div")
            .attr("class", "element_inner");
        /*if (this.stateInfo.role == ROLE_QUESTION_SORT.TEAM) inner
            .attr("draggable", true)
            .classed("draggable", true)
            .on("dragstart", (_, d) => {
                this.draggedElement = d.id;
                this.sortDiv.select("#question_sort_content").classed("positioning", true);
            })
            .on("dragend", () => {
                this.draggedElement = -1;
                this.sortDiv.select("#question_sort_content").classed("positioning", false);
            });*/
        inner.append("div")
            .attr("class", "name")
            .call(div => div.append("div")
                .attr("class", "name_wrapper")
                .call(div => div.append("span")
                    .style("width", "calc( " + this.state.elements_max_name_length + "em + 1px )"))
                .call(div => div.append("img")
                    .attr("sizes", "2.2em")
                    .on("click", (_, d) => {
                        this.fullscreenImage.attr("src", d.img_url);
                        this.fullscreenImage.node().requestFullscreen();
                    })
                )
            )
            .call(addEdges, ".name_wrapper")
            .call(div => {
                if (this.stateInfo.role != ROLE_QUESTION_SORT.ADMIN) return;
                div.append("div").attr("class", "button_show_element")
                    .on("click", (_, d) => this.sendMessage({
                        type: MESSAGE_QUESTION_SORT.SHOW_ELEMENT,
                        element_id: d.id
                    }));
            })
            .call(div => {
                if (this.stateInfo.role != ROLE_QUESTION_SORT.ADMIN) return;
                div.append("div").attr("class", "button_order_element")
                    .on("click", (_, d) => this.sendMessage({
                        type: MESSAGE_QUESTION_SORT.ORDER_ELEMENT,
                        element_id: d.id
                    }));
            });

        return element;
    }

    /**
     * @param {ElementSelection} update 
     * @returns {ElementSelection}
     */
    elementUpdate(update) {
        update.call(updateInfoDiv, d => d.info);
        update.classed("halfvisible", d => !d.visible && this.stateInfo.role == ROLE_QUESTION_SORT.ADMIN)
            .classed("fade_out", d => !d.visible && this.stateInfo.role != ROLE_QUESTION_SORT.ADMIN)
            .classed("wrong_position", d => d.position_visible);
        update.select(".name span")
            .classed("hidden", d => !d.name)
            .text(d => d.name);
        update.select(".name img")
            .classed("hidden", d => !d.img_url)
            .filter(d => d.img_url)
                .attr("src", d => {
                    let index = d.img_url.lastIndexOf(".");
                    return d.img_url.slice(0, index) + "_thumbnail" + d.img_url.slice(index);
                });

        update.select(".button_show_element").classed("hidden", this.state.phase != PHASE_QUESTION_SORT.QUESTION);
        update.select(".button_order_element").classed("hidden", this.state.phase != PHASE_QUESTION_SORT.RESULTS);
        return update;
    }

    /**
     * @param {ElementSelection} exit 
     */
    elementExit(exit) {
        exit.classed("element_state", false)
            .classed("element_remove", true)
            .on("animationend", function() {
                d3.select(this).remove();
            });
    }


    /**
     * * NOTES
     */

    updateNotes() {
        let noteData = this.state.teams.map(t => ({
            id: t.id,
            notes: t.notes.map((n, index, notes) => {
                let element = this.state.elements.find(e => e.id == n.element_id);

                let wrongPosition = false;
                if (element.position_visible) {
                    let position = this.state.order.findIndex(o => o == element.id);
                    for (let i = index-1; i >= 0 && !wrongPosition; i--) {
                        if (!notes[i].fixed) continue;
                        if (this.state.order.findIndex(o => o == notes[i].element_id) > position) wrongPosition = true;
                    }
                    for (let i = index+1; i < notes.length && !wrongPosition; i++) {
                        if (!notes[i].fixed) continue;
                        if (this.state.order.findIndex(o => o == notes[i].element_id) < position) wrongPosition = true;
                    }
                }
                let switched_up = false;
                let switched_down = false;
                let oldDataTeam = this.oldState.teams.find(ot => ot.id == t.id);
                let oldPosition = oldDataTeam ? oldDataTeam.notes.findIndex(no => no.element_id == n.element_id) : -1;
                if (!n.fixed && oldPosition >= 0) {
                    let diff = index - oldPosition;
                    let sel = this.sortDiv.selectAll(".question_sort_notes_inner").filter(d => d.id == t.id).selectAll(".element_state").filter(d => d.id == n.element_id);
                    if (Math.abs(diff) >= 2) {
                        if (diff > 0) {
                            switched_down = true;
                            sel.classed("element_remove_down", true);
                        } else {
                            switched_up = true;
                            sel.classed("element_remove_up", true);
                        }
                    } else if (Math.abs(diff) == 1) {
                        let otherIndex;
                        if (diff > 0) {
                            otherIndex = index-1;
                        } else {
                            otherIndex = index+1;
                        }
                        if (otherIndex >= 0 && otherIndex < notes.length) {
                            let otherElement = notes[otherIndex];
                            let otherOldDataTeam = this.oldState.teams.find(ot => ot.id == t.id);
                            let otherOldPosition = otherOldDataTeam ? otherOldDataTeam.notes.findIndex(no => otherElement.element_id == no.element_id) : -1;
                            if (diff > 0) {
                                if (otherIndex - otherOldPosition == -1) {
                                    switched_down = true;
                                    sel.classed("element_remove_down", true);
                                }
                            } else {
                                if (otherIndex - otherOldPosition == 1 && otherElement.fixed) {
                                    switched_up = true;
                                    sel.classed("element_remove_up", true);
                                }
                            }
                        }
                    }
                    if (switched_up || switched_down) sel
                        .classed("element_state", false)
                        .on("animationend", () => sel.remove());
                }

                return {
                    id: n.element_id,
                    element_id: n.element_id,
                    suggested: n.suggested,
                    fixed: n.fixed,
                    wrongPosition: wrongPosition,
                    tag: n.tag,
                    text: n.text,
                    info: element.info,
                    name: element.name,
                    img_url: element.img_url,
                    value: element.value,
                    visible: element.visible,
                    switched_up: switched_up,
                    switched_down: switched_down
                }
        })}));

        this.sortDiv.select("#question_sort_column_right").selectAll(".question_sort_note_div")
            .data(noteData, d => d.id)
            .join(
                this.notesEnter.bind(this)
            )
            .call(this.notesUpdate.bind(this));
    }

    notesEnter(enter) {
        let div = enter.append("div")
            .attr("class", "question_sort_note_div")
            .classed("hidden", d => this.stateInfo.role != ROLE_QUESTION_SORT.TEAM && d.id != this.state.moderation_team_id);

        div.append("div")
            .attr("class", "top_limit limit hidden");
        div.append("div")
            .attr("class", "question_sort_notes_outer")
            .append("div")
                .attr("class", "question_sort_notes_inner");
        div.append("div")
            .attr("class", "bottom_limit limit hidden");

        return div;
    }

    notesUpdate(update) {

        if (this.stateInfo.role != ROLE_QUESTION_SORT.TEAM && this.state.moderation_team_id != this.oldState.moderation_team_id) {
            let oldMod = update.filter(d => d.id == this.oldState.moderation_team_id);
            oldMod.classed("fade_out_top", this.state.moderation_team_id > this.oldState.moderation_team_id)
                .classed("fade_out_bottom", this.state.moderation_team_id < this.oldState.moderation_team_id)
                .classed("fade_in_top", false)
                .classed("fade_in_bottom", false)
                .on("animationend", null)
                .on("transitionend", e => {
                    if (e.target != e.currentTarget) return;
                    oldMod.classed("fade_out_top", false)
                        .classed("fade_out_bottom", false)
                        .classed("hidden", true);
                });
            let newMod = update.filter(d => d.id == this.state.moderation_team_id);
            newMod.classed("hidden", false)
                .classed("fade_in_top", this.state.moderation_team_id < this.oldState.moderation_team_id)
                .classed("fade_in_bottom", this.state.moderation_team_id > this.oldState.moderation_team_id)
                .classed("fade_out_top", false)
                .classed("fade_out_bottom", false)
                .on("transitionend", null)
                .on("animationend", e => {
                    if (e.target != e.currentTarget) return;
                    newMod.classed("fade_in_top", false)
                        .classed("fade_in_bottom", false);
                });
        }

        update.select(".question_sort_notes_inner").selectAll(".element_state")
            .data(d => d.notes.filter(d => !d.switched), d => d.id)
            .join(
                this.noteEnter.bind(this),
                update => update,
                this.noteExit.bind(this)
            )
            .call(this.noteUpdate.bind(this));
        let noteElements = update.select(".question_sort_notes_inner").selectAll(".element_state")
            .data(d => d.notes, d => d.id)
            .join(
                this.noteEnter.bind(this),
                update => update,
                this.noteExit.bind(this)
            )
            .call(this.noteUpdate.bind(this));

        let noteGapElements = update.select(".question_sort_notes_inner").selectAll(".element_gap")
            .data(d => {
                let data = d.notes;
                let noteDataGap = Array(data.length+1).fill(0).map((_, i) => ({
                    position: i,
                    previous: i > 0 ? data[i-1].id : -1,
                    next: i < data.length ? data[i].id : -1
                }));
                return noteDataGap;
            }, d => JSON.stringify(d))
            .join(
                this.noteGapEnter.bind(this),
                update => update,
                this.noteGapExit.bind(this)
            )
            .call(this.noteGapUpdate.bind(this));
        
        return update;
    }

    /**
     * @param {ElementWithNoteSelection} enter 
     * @returns {ElementWithNoteSelection}
     */
    noteEnter(enter) {
        let element = enter.append("div")
            .attr("class", "element element_state")
            .call(createInfoDiv);
        element.filter(d => d.switched_up).classed("switched_up", true);
        element.filter(d => d.switched_down).classed("switched_down", true);
        let inner = element.append("div")
            .attr("class", "element_inner");

        if (this.stateInfo.role == ROLE_QUESTION_SORT.TEAM) {
            inner.filter(d => !d.fixed)
                .attr("draggable", true)
                .classed("draggable", true)
                .on("dragstart", (_, d) => {
                    this.draggedElement = d.id;
                    this.sortDiv.select("#question_sort_content").classed("positioning", true);
                })
                .on("dragend", () => {
                    this.draggedElement = -1;
                    this.sortDiv.select("#question_sort_content").classed("positioning", false);
                });
            inner.append("div")
                .attr("class", "selections")
                // * Button to submit an answer
                /*.call(div => div.append("div")
                    .attr("class", "selector")
                    .on("click", (_, d) => {
                        let index = this.state.teams[0].notes.findIndex(n => n.element_id == d.id);
                        let position = 0;
                        for (; index >= 0; index--) {
                            if (this.state.teams[0].notes[index].fixed) {
                                position = this.state.order.findIndex(o => o == this.state.teams[0].notes[index].element_id) + 1;
                                break;
                            };
                        }
                        this.sendMessage({
                            type: MESSAGE_QUESTION_SORT.ANSWER,
                            element_id: d.id,
                            position: position
                        });
                    }))*/
                .call(div => div.append("div")
                    .attr("class", "suggestor")
                    .on("click", (_, d) => this.sendMessage({
                        type: MESSAGE_QUESTION_SORT.NOTE_SUGGEST,
                        element_id: d.id
                    })));
        }

        inner.append("div")
            .attr("class", "number")
            .call(div => div.append("span"))
            .call(addEdges, "span");
        inner.append("div")
            .attr("class", "name").call(div => div.append("div")
                .attr("class", "name_wrapper")
                .call(div => div.append("span")
                    .style("width", "calc( " + this.state.elements_max_name_length + "em + 1px )"))
                .call(div => div.append("img")
                    .attr("sizes", "2.2em")
                    .on("click", (_, d) => {
                        this.fullscreenImage.attr("src", d.img_url);
                        this.fullscreenImage.node().requestFullscreen();
                    })
                )
            )
            .call(addEdges, ".name_wrapper", true);
        inner.append("div")
            .attr("class", "tag")
            .call(div => div.filter(() => this.stateInfo.role == ROLE_QUESTION_SORT.TEAM).append("div")
                .attr("class", "tag_selection")
                .call(div => div.append("div")
                    .attr("class", "tag_selection_none")
                    .on("click", (_, d) => this.sendMessage({
                        type: MESSAGE_QUESTION_SORT.NOTE_TAG,
                        element_id: d.element_id,
                        tag: TAG_QUESTION_SORT.NONE
                    })))
                .call(div => div.append("div")
                    .attr("class", "tag_selection_sure")
                    .on("click", (_, d) => this.sendMessage({
                        type: MESSAGE_QUESTION_SORT.NOTE_TAG,
                        element_id: d.element_id,
                        tag: TAG_QUESTION_SORT.SURE
                    })))
                .call(div => div.append("div")
                    .attr("class", "tag_selection_unsure")
                    .on("click", (_, d) => this.sendMessage({
                        type: MESSAGE_QUESTION_SORT.NOTE_TAG,
                        element_id: d.element_id,
                        tag: TAG_QUESTION_SORT.UNSURE
                    })))
            )
            .call(div => div.append("span"))
            .call(addEdges, "span", true)
        inner.append("div")
            .attr("class", "text")
            .call(div => div.append("span")
                .filter(() => this.stateInfo.role == ROLE_QUESTION_SORT.TEAM)
                .attr("contenteditable", true)
                .classed("editable", true)
                .on("keydown", (e, d) => {
                    e.stopPropagation();
                    let target = d3.select(e.currentTarget);
                    if (e.key == "Escape") {
                        target.text(d.text);
                        target.node().blur();
                        return;
                    }
                    let text = target.text();
                    if (e.key == "Enter") {
                        e.preventDefault();
                        if (text == d.text) return;
                        this.sendMessage({
                            type: MESSAGE_QUESTION_SORT.NOTE_TEXT,
                            element_id: d.id,
                            text: text
                        });
                        target.node().blur();
                    } else {
                        target.classed("input_to_long", text.length > SETTINGS_QUESTION_SORT.NOTE_TEXT_LENGTH); // TODO css
                    }
                    
                })
                .on("focusout", (e, d) => {
                    let target = d3.select(e.currentTarget);
                    let text = target.text();
                    if (text == d.text) return;
                    this.sendMessage({
                        type: MESSAGE_QUESTION_SORT.NOTE_TEXT,
                        element_id: d.id,
                        text: text
                    });
                }))
            .call(addEdges, "span", true);

        return element;
    }

    /**
     * @param {ElementWithNoteSelection} update 
     * @returns {ElementWithNoteSelection}
     */
    noteUpdate(update) {
        update.call(updateInfoDiv, d => d.info);
        update.classed("fixed_position", d => d.fixed)
            .classed("suggested_answer", d => d.suggested)
            .classed("wrong_position", d => d.wrongPosition)
            .classed("fade_out", d => !d.visible && this.stateInfo.role != ROLE_QUESTION_SORT.ADMIN);
        if (this.stateInfo.role == ROLE_QUESTION_SORT.TEAM) {
            update.filter(d => d.fixed).select(".element_inner")
                .attr("draggable", false)
                .classed("draggable", false)
                .on("dragstart", null)
                .on("dragend", null);
            update.select(".selections")
                .classed("hidden", (d, index) => {
                    if (this.state.phase != PHASE_QUESTION_SORT.ANSWERS || d.fixed || d.wrongPosition) return true;
                    let topNoteIndex = -1;
                    let bottomNoteIndex = this.state.order.length;
                    for (let i = index; i >= 0; i--) {
                        let note = this.state.teams[0].notes[i];
                        if (note.fixed) {
                            topNoteIndex = this.state.order.findIndex(o => o == note.element_id);
                            break;
                        }
                    }
                    for (let i = index; i < this.state.teams[0].notes.length; i++) {
                        let note = this.state.teams[0].notes[i];
                        if (note.fixed) {
                            bottomNoteIndex = this.state.order.findIndex(o => o == note.element_id);
                            break;
                        }
                    }
                    if (bottomNoteIndex - topNoteIndex > 1) return true;
                    return false;
                })
                .select(".selector")
                    .classed("hidden", this.state.active_team_id != this.stateInfo.team_id);
        }
        update.select(".number > span")
            .text((_, i) => (i+1) + ".");
        update.select(".name span")
            .classed("hidden", d => !d.name)
            .text(d => d.name);
        update.select(".name img")
            .classed("hidden", d => !d.img_url)
            .filter(d => d.img_url)
                .attr("src", d => {
                    let index = d.img_url.lastIndexOf(".");
                    return d.img_url.slice(0, index) + "_thumbnail" + d.img_url.slice(index);
                })
        update.select(".tag")
            .classed("tag_none", d => d.tag == TAG_QUESTION_SORT.NONE)
            .classed("tag_sure", d => d.tag == TAG_QUESTION_SORT.SURE)
            .classed("tag_unsure", d => d.tag == TAG_QUESTION_SORT.UNSURE);
        update.select(".text > span")
            .classed("input_to_long", false)
            .text(d => d.text)
            .style("width", null)
            .style("width", (_, __, nodes) => nodes.reduce((a, b) => Math.max(a, parseFloat(d3.select(b).style("width"))), 0)); // TODO scaling with rem size
        return update;
    }

    /**
     * @param {ElementWithNoteSelection} exit 
     */
    noteExit(exit) {
        exit.classed("element_state", false)
            .classed("element_remove", true)
            .on("animationend", function() {
                d3.select(this).remove();
            });
    }

    /**
     * @param {d3.Selection} enter 
     * @returns {d3.Selection}
     */
    answerGapEnter(enter) {
        let elements = this.sortDiv.select("#question_sort_answers_inner").selectAll(".element_state");
        let element = enter.insert("div", function(d) {
                if (d.next < 0) return null;
                return elements.filter(dn => dn.id == d.next).node();
            })
        //enter.insert("div", (_, i) => i < elementNodes.length ? elementNodes[i] : null)
            .attr("class", "element_gap");
        if (this.stateInfo.role == ROLE_QUESTION_SORT.TEAM) element
            .on("dragenter", (e) => {
                d3.select(e.currentTarget).classed("dragover", true);
            })
            .on("dragleave", (e) => {
                d3.select(e.currentTarget).classed("dragover", false);
            })
            .on("dragover", (e) => e.preventDefault())
            .on("drop", (e, d) => {
                let element = this.state.elements.find(e => e.id == this.draggedElement);
                let target = d3.select(e.currentTarget)
                    .classed("dragover", false);
                this.sendMessage({
                    type: MESSAGE_QUESTION_SORT.ANSWER,
                    element_id: this.draggedElement,
                    position: d.position
                });
            });
        return element;
    }

    /**
     * @param {d3.Selection} update 
     * @returns {d3.Selection}
     */
    answerGapUpdate(update) {
        return update;
    }

    /**
     * @param {d3.Selection} exit 
     */
    answerGapExit(exit) {
        exit.remove();
    }

    /**
     * @param {d3.Selection} enter 
     * @returns {d3.Selection}
     */
    noteGapEnter(enter) {
        let element = enter.insert("div", function(d) {
                if (d.next < 0) return null;
                return d3.select(this._parent).selectAll(".element_state").filter(dn => dn.id == d.next).node();
            })
            .attr("class", "element_gap");
        if (this.stateInfo.role == ROLE_QUESTION_SORT.TEAM) element
            .on("dragenter", (e) => {
                d3.select(e.currentTarget).classed("dragover", true);
            })
            .on("dragleave", (e) => {
                d3.select(e.currentTarget).classed("dragover", false);
            })
            .on("dragover", (e) => e.preventDefault())
            .on("drop", (e, d) => {
                let element = this.state.elements.find(e => e.id == this.draggedElement);
                let target = d3.select(e.currentTarget)
                    .classed("dragover", false);
                this.sendMessage({
                    type: MESSAGE_QUESTION_SORT.NOTE_ORDER,
                    element_id: this.draggedElement,
                    position: d.position,
                    number_elements: this.state.teams[0].notes.length
                });
            });
        return element;
    }

    /**
     * @param {d3.Selection} update 
     * @returns {d3.Selection}
     */
    noteGapUpdate(update) {
        return update;
    }

    /**
     * @param {d3.Selection} exit 
     */
    noteGapExit(exit) {
        exit.remove();
    }
}