import { GUIManager } from "../guiManager.js";
import { GUIObject } from "../guiObject.js";
import { Question } from "./question.js";
import { CLASSID, PHASE, MODE, MESSAGE, SUGGESTION, NOTE_TAG, SETTINGS } from "../definitions/questions/list.defs.js";
import { createRoot } from "react-dom/client";
import { CTriangleBox } from "../elements/triangles.js";
import { CControlButtonMini, CQuestionContentControls, CQuestionContentControlsButton, CQuestionContentControlsPhaseButton } from "../elements/contentControls.js";
import { getLanguageText } from "../language.js";

import "../../styles/questions/list.css";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { CHoverSelectorList } from "../elements/hoverSelectorList.js";
import { calculateMaxPixelWidth } from "../utils/pixelWidth.js";
import { TeamViewSelectorWithGeneralView } from "../elements/teamViewSelector.js";



/**
 * Time for which a new answer is marked in milliseconds.
 */
const TIME_NEW_ANSWER = 5000;

/**
 * Time for which a changed note is marked in milliseconds.
 */
const TIME_NOTE_CHANGED = 5000;


/**
 * Lookup table for the display text of a tag.
 * @type {Map<NOTE_TAG,string>}
 */
const TAG_TEXT_LOOKUP = new Map([
    [NOTE_TAG.NONE, ""],
    [NOTE_TAG.SURE, "✓"],
    [NOTE_TAG.UNSURE, "?"],
    [NOTE_TAG.WRONG, "✗"]
]);

/**
 * Lookup table for the css class of a tag.
 * @type {Map<NOTE_TAG,string>}
 */
const TAG_CLASS_LOOKUP = new Map([
    [NOTE_TAG.NONE, ""],
    [NOTE_TAG.SURE, " sure"],
    [NOTE_TAG.UNSURE, " unsure"],
    [NOTE_TAG.WRONG, " wrong"]
]);

/**
 * classid {@link CLASSID}
 * @extends Question<import("../definitions/questions/list.defs.js").State,import("../definitions/questions/list.defs.js").StateInfo>
 */
export class Question_List extends Question {

    /**
     * @type {import("react-dom/client").Root}
     */
    root;

    /**
     * @type {TeamViewSelectorWithGeneralView}
     */
    teamViewSelector;

    /**
     * @type {number}
     */
    activeTeamView;


    /**
     * @param {GUIManager} guiManager 
     * @param {*} message 
     */
    constructor(guiManager, message) {
        super(guiManager, message);

        const element = document.getElementById("question_content");
        this.root = createRoot(element);

        if (this.stateInfo.isAdmin) {
            if (this.stateInfo.isModerator) {
                this.teamViewSelector = new TeamViewSelectorWithGeneralView(
                    id => this.sendMessage({
                        type: MESSAGE.MODERATED_TEAM,
                        team_id: id
                    }),
                    () => this.sendMessage({
                        type: MESSAGE.MODERATED_TEAM,
                        team_id: -1
                    })
                );
            } else {
                this.teamViewSelector = new TeamViewSelectorWithGeneralView(
                    id => {
                        this.activeTeamView = id;
                        this.update();
                    },
                    () => {
                        this.activeTeamView = -1;
                        this.update();
                    }
                );
            }
        }

        this.activeTeamView = -1;
        if (this.state.notes.length == 1) {
            this.activeTeamView = this.state.notes[0].team_id;
        }
    }

    destroy() {
        if (this.teamViewSelector) this.teamViewSelector.destroy();
        this.root.unmount();
        super.destroy();
    }

    update() {
        super.update();
        this.startPosition(this.state.phase == PHASE.START);

        const activeTeamView = this.stateInfo.isModerator ? this.state.moderated_team_id : this.activeTeamView;

        this.root.render(
            <CQuestionList 
                state={this.state}
                stateInfo={this.stateInfo}
                activeTeamView={activeTeamView}
                sendMessage={this.sendMessage.bind(this)}
            />
        );
    }
}


/**
 * @type {React.Context<{
 *  state: import("../definitions/questions/list.defs.js").State
 *  stateInfo: import("../definitions/questions/list.defs.js").StateInfo
 *  sendMessage: (Object) => void
 *  activeTeamView: number
 *  max_predefined_length: number
 * }>}
 */
const Question_List_Context = createContext({
    state: null,
    stateInfo: null,
    sendMessage: null,
    activeTeamView: -1,
    max_predefined_length: 0
});

/**
 * @param {Object} props 
 * @param {import("../definitions/questions/list.defs.js").State} props.state
 * @param {import("../definitions/questions/list.defs.js").StateInfo} props.activeTeamView
 * @param {number} props.stateInfo
 * @param {(Object) => void} props.sendMessage
 */
function CQuestionList({state, stateInfo, activeTeamView, sendMessage}) {
    const maxPredefinedLength = calculateMaxPixelWidth(state.answers.map(a => a.predefined));
    return (
        <Question_List_Context.Provider value={{
                state: state,
                stateInfo: stateInfo,
                sendMessage: sendMessage,
                activeTeamView: activeTeamView,
                max_predefined_length: maxPredefinedLength}} >
            <CQuestionListContent />
            <CQuestionListControls />
        </Question_List_Context.Provider>
    );
}

/**
 * @param {Object} props 
 */
function CQuestionListContent({}) {
    const context = useContext(Question_List_Context);
    let classes = "question_list_content";
    if (context.state.phase === PHASE.START) classes += " invisible";
    return (
        <div className={classes}>
            <CAnswerList />
            <CNotes />
        </div>
    );
}

/**
 * @param {Object} props 
 */
function CQuestionListControls({}) {
    const context = useContext(Question_List_Context);
    if (!context.stateInfo.isAdmin) return null;

    const phases = new Map([
        [PHASE.START, getLanguageText("phase_start")],
        [PHASE.QUESTION, getLanguageText("phase_question")],
        [PHASE.PREPARATION, getLanguageText("phase_preparation")],
        [PHASE.PREPARATION_END, getLanguageText("phase_preparationend")],
        [PHASE.ANSWERS, getLanguageText("phase_answers")],
        [PHASE.RESULTS, getLanguageText("phase_results")],
        [PHASE.FINISHED, getLanguageText("phase_finished")]
    ]);

    const buttonWrongAnswerText = getLanguageText("button_wrong_answer_text");
    const buttonShowInfosText = getLanguageText("button_show_infos_text");

    const buttonWrongAnswerVisible = context.state.phase === PHASE.ANSWERS;
    const buttonShowInfosVisible = context.state.phase === PHASE.RESULTS && !context.state.result_infos_visible;

    const handleNextPhase = () => context.sendMessage({
        type: MESSAGE.NEXT_PHASE,
        current_phase: context.state.phase
    });
    const handleWrongAnswer = () => context.sendMessage({
        type: MESSAGE.WRONG_ANSWER
    });
    const handleShowInfos = () => context.sendMessage({
        type: MESSAGE.SHOW_INFOS
    });
    
    const controlButtons = <>
        <CQuestionContentControlsPhaseButton 
            key="buttonNextPhase"
            phases={phases} 
            currentPhase={context.state.phase} 
            onNextPhase={handleNextPhase} 
        />
        <CQuestionContentControlsButton 
            key="buttonWrongAnswerText"
            children={buttonWrongAnswerText} 
            visible={buttonWrongAnswerVisible} 
            onClick={handleWrongAnswer} 
        />
        <CQuestionContentControlsButton 
            key="buttonShowInfosText"
            children={buttonShowInfosText} 
            visible={buttonShowInfosVisible} 
            onClick={handleShowInfos} 
        />
    </>;

    return (
        <CQuestionContentControls children={controlButtons} />
    );
}


/**
 * @param {Object} props 
 */
function CAnswerList({}) {
    const context = useContext(Question_List_Context);
    const answerData = context.state.mode == MODE.CALLED ? 
        Array.from(context.state.answers).sort((a, b) => a.order < 0 ? 1 : b.order < 0 ? -1 : a.order - b.order) : 
        context.state.answers;
    const answers = answerData.map(a => {
        return (
            <CAnswer 
                key={a.id}
                answer={a}
            />
        );
    });
    const counterText = context.stateInfo.isAdmin ? 
        context.state.answers.filter(a => a.visible).length + " / " + context.state.answers.length : 
        context.state.answers.length;

    return (
        <div className="answers">
            <div className="answers_grid">
                {answers}
            </div>
            <div className="counter">
                {counterText}
            </div>
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {import("../definitions/questions/list.defs.js").Answer} props.answer
 */
function CAnswer({answer}) {
    const context = useContext(Question_List_Context);
    const ownRef = useRef();
    const [newAnswer, setNewAnswer] = useState(false);
    let classes = "answer";
    if (newAnswer) classes += " new";
    if (answer.suggestion === SUGGESTION.RIGHT) classes += " suggestion_right";
    if (answer.suggestion === SUGGESTION.MAYBE) classes += " suggestion_maybe";
    if (answer.suggestion === SUGGESTION.WRONG) classes += " suggestion_wrong"; 
    /*if (mode == MODE.ORDERED) classes += " create_space"; // TODO css */
    
    useEffect(() => {
        if (!answer.visible) return;
        if (![PHASE.ANSWERS, PHASE.RESULTS].includes(context.state.phase)) return;
        ownRef.current.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    }, [answer.visible]);

    useEffect(() => {
        if (!answer.visible) return
        setNewAnswer(true);
        const timeout = setTimeout(() => setNewAnswer(false), TIME_NEW_ANSWER);
        return () => clearTimeout(timeout);
    }, [answer.visible]);

    return (
        <div ref={ownRef} className={classes}>
            <CAnswerPredefined 
                predefined={answer.predefined}
            />
            <CAnswerText 
                answer={answer}
            />
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {string} [props.predefined] 
 */
function CAnswerPredefined({predefined}) {
    return (
        <div className="predefined">
            <CTriangleBox>
                <span>{predefined}</span>
            </CTriangleBox>
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {import("../definitions/questions/list.defs.js").Answer} props.answer 
 */
function CAnswerText({answer}) {
    const context = useContext(Question_List_Context);
    if (!answer.text) return null;
    let classes = "text";
    if (!answer.visible) {
        classes += " not_visible";
    }

    return (
        <div className={classes}>
            <CTriangleBox leftInner={true} info={answer.info}>
                <span style={{minWidth: context.state.max_text_length + "em"}}>
                    {answer.text}
                </span>
                <CAnswerControls
                    answer={answer}
                />
            </CTriangleBox>
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {import("../definitions/questions/list.defs.js").Answer} props.answer 
 */
function CAnswerControls({answer}) {
    const context = useContext(Question_List_Context);
    if (answer.visible || ![PHASE.ANSWERS, PHASE.RESULTS].includes(context.state.phase)) return null;
    if (!context.stateInfo.isAdmin) return null;

    const handleSuggestRight = () => context.sendMessage({
        type: MESSAGE.SUGGEST_ANSWER,
        answer_id: answer.id,
        suggestion: SUGGESTION.RIGHT
    });
    const handleSuggestMaybe = () => context.sendMessage({
        type: MESSAGE.SUGGEST_ANSWER,
        answer_id: answer.id,
        suggestion: SUGGESTION.MAYBE
    });
    const handleSuggestWrong = () => context.sendMessage({
        type: MESSAGE.SUGGEST_ANSWER,
        answer_id: answer.id,
        suggestion: SUGGESTION.WRONG
    });
    const handleShowAnswer = () => context.sendMessage({
        type: MESSAGE.SHOW_ANSWER,
        answer_id: answer.id
    });

    return (
        <div className="answer_controls" >
            {context.state.phase === PHASE.ANSWERS && <div className="buttons_suggest">
                <CControlButtonMini
                    key="buttonSuggestRight"
                    text="✓"
                    square={true}
                    onClick={handleSuggestRight} 
                />
                <CControlButtonMini
                    key="buttonSuggestMaybe"
                    text="?"
                    square={true}
                    onClick={handleSuggestMaybe} 
                />
                <CControlButtonMini
                    key="buttonSuggestWrong"
                    text="✘"
                    square={true}
                    onClick={handleSuggestWrong} 
                />
            </div>}
            <CControlButtonMini
                key="buttonShowAnswer"
                text="👁"
                square={true}
                onClick={handleShowAnswer} 
            />
        </div>
    );
}


/**
 * TODO animation on change
 * TODO animation new note
 * TODO suggest
 * @param {Object} props 
 */
function CNotes({}) {
    const context = useContext(Question_List_Context);
    const teamNotes = context.state.notes.map(n => {
        return (
            <CNotesList 
                key={n.team_id}
                notes={n} 
            />
        );
    });
    if (teamNotes.length === 0) return null;

    return (
        <div className="notes">
            {teamNotes.length >= 2 && <CNotesList 
                key={-1}
                notes={{entries: [], team_id: -1}} 
            />}
            {teamNotes}
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {import("../definitions/questions/list.defs.js").TeamNote} props.notes
 */
function CNotesList({notes}) {
    const context = useContext(Question_List_Context);
    const ownRef = useRef();

    if (context.activeTeamView == notes.team_id) {
        ownRef.current?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    }

    if (notes.team_id == -1) {
        return (
            <div ref={ownRef} className="notes_grid_wrapper"></div>
        );
    }

    const entries = notes.entries.map(n => {
        return (
            <CNote 
                key={n.id}
                note={n}
            />
        );
    });
    
    const counterText = notes.entries.length;

    return (
        <div ref={ownRef} className="notes_grid_wrapper">
            <div className="notes_grid">
                {entries}
                <CAddNote />
            </div>
            <div className="counter">
                {counterText}
            </div>
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {import("../definitions/questions/list.defs.js").NoteEntry} props.note
 */
function CNote({note}) {
    const context = useContext(Question_List_Context);
    let classes = "note";

    const [changed, setChanged] = useState(true);

    if (changed) classes += " changed";

    useEffect(() => {
        setChanged(true);
        const timeout = setTimeout(() => setChanged(false), TIME_NOTE_CHANGED);
        return () => clearTimeout(timeout);
    }, [note.predefined_id, note.tag, note.text]);

    const predefinedList = [MODE.PREDEFINED, MODE.ASSIGN].includes(context.state.mode) ? 
        context.state.answers.map(a => ({
            id: a.id,
            text: a.predefined,
            visible: a.visible
        })) : [];

    return (
        <div className={classes}>
            <CNotePredefined 
                noteId={note.id}
                predefinedId={note.predefined_id}
                predefinedList={predefinedList}
            />
            <CNoteTag 
                noteId={note.id} 
                tag={note.tag} 
            />
            <CNoteText 
                noteId={note.id}
                text={note.text}
            />
            <CNoteId 
                noteId={note.id}
            />
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.noteId
 * @param {number} props.predefinedId
 * @param {Object[]} props.predefinedList
 * @param {number} props.predefinedList[].id
 * @param {string} props.predefinedList[].text
 * @param {boolean} props.predefinedList[].visible
 */
function CNotePredefined({noteId, predefinedId, predefinedList}) {
    const context = useContext(Question_List_Context);
    let classes = "predefined";
    if (predefinedList.length > 0 && predefinedList.find(p => p.id == predefinedId)?.visible)
        classes += " visible";
    const text = predefinedList.length > 0 ? 
        predefinedList.find(p => p.id == predefinedId)?.text : "";

    const handleSelectPredefined = (id) => context.sendMessage({
        type: MESSAGE.SET_NOTE_PREDEFINED,
        note_id: noteId,
        predefined_id: id
    });
    const selectors = predefinedList.filter(p => p.id !== predefinedId).map(p => ({
        text: p.text,
        onClick: () => handleSelectPredefined(p.id)
    }));
    const showSelectors = context.state.mode === MODE.PREDEFINED && !context.stateInfo.isAdmin && !context.stateInfo.isModerator;

    return (
        <div className={classes}>
            <CTriangleBox>
                {showSelectors && <CHoverSelectorList selectors={selectors} fadeDown={true} />}
                <span style={{minWidth:context.max_predefined_length + "em"}}>{text}</span>
            </CTriangleBox>
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.noteId
 * @param {import("../definitions/questions/list.defs.js").NOTE_TAG} props.tag
 */
function CNoteTag({noteId, tag}) {
    const context = useContext(Question_List_Context);
    const text = TAG_TEXT_LOOKUP.get(tag);
    const classes = "tag" + TAG_CLASS_LOOKUP.get(tag);
    const handleSelectTag = (tag) => context.sendMessage({
        type: MESSAGE.SET_NOTE_TAG,
        note_id: noteId,
        tag: tag
    });
    let selectors = [];
    for (let entry of TAG_TEXT_LOOKUP.entries()) {
        if (tag === entry[0]) continue;
        selectors.push({text: entry[1] === "" ? "_" : entry[1], onClick: () => handleSelectTag(entry[0])});
    }
    const showSelectors = !context.stateInfo.isAdmin && !context.stateInfo.isModerator;
    return (
        <div className={classes}>
            <CTriangleBox leftInner={true}>
                {showSelectors && <CHoverSelectorList selectors={selectors} fadeDown={true} />}
                <span>{text}</span>
            </CTriangleBox>
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.noteId
 * @param {string} props.text
 */
function CNoteText({noteId, text}) {
    const context = useContext(Question_List_Context);
    const classes = "text";
    const [value, setValue] = useState(text);

    useEffect(() => setValue(text), [text]);

    /**
     * @param {KeyboardEvent} e 
     */
    const handleKeyDown = e => {
        if (e.key === "Escape") {
            e.currentTarget.blur();
            setValue(text);
            return;
        }
        if (e.key === "Enter") {
            sendValue();
            return;
        }
    }

    const sendValue = () => context.sendMessage({
        type: MESSAGE.EDIT_NOTE,
        note_id: noteId,
        text: value
    });

    const showInput = !context.stateInfo.isAdmin && !context.stateInfo.isModerator;

    return (
        <div className={classes}>
            <CTriangleBox leftInner={true}>
                {showInput && <input 
                    type="text" 
                    maxLength={SETTINGS.NOTE_MAX_TEXT_LENGTH} 
                    value={value} 
                    className={"editable"} 
                    onKeyDown={handleKeyDown} 
                    onChange={e => setValue(e.currentTarget.value)} 
                    onBlur={sendValue}
                />}
                <span>{value}</span> 
            </CTriangleBox>
        </div>
    );
}

/**
 * @param {Object} props 
 * @param {number} props.noteId
 */
function CNoteId({noteId}) {
    const context = useContext(Question_List_Context);
    let classes = "id";
    
    const canDelete = !context.stateInfo.isAdmin && !context.stateInfo.isModerator;
    if (canDelete) classes += " delete";

    const handleClick = canDelete ? 
        () => context.sendMessage({
            type: MESSAGE.REMOVE_NOTE,
            note_id: noteId
        }) : null;

    return (
        <div className={classes} onClick={handleClick}>
            <CTriangleBox leftInner={true}>
                {canDelete && <span className="delete">{"X"}</span>}
                <span>{noteId}</span>
            </CTriangleBox>
        </div>
    );
}

/**
 * @param {Object} props 
 */
function CAddNote({}) {
    const context = useContext(Question_List_Context);
    if (context.stateInfo.isAdmin || context.stateInfo.isModerator) return null;
    if (context.state.mode === MODE.ASSIGN) return null;
    const classes = "add_note";
    return (
        <div className={classes}>
            <CAddNotePlus />
            <CAddNoteText />
        </div>
    );
}

/**
 * @param {Object} props 
 */
function CAddNotePlus({}) {
    const classes = "add";
    return (
        <div className={classes}>
            <CTriangleBox>
                <span>{"+"}</span>
            </CTriangleBox>
        </div>
    );
}

/**
 * @param {Object} props 
 */
function CAddNoteText({}) {
    const context = useContext(Question_List_Context);
    const classes = "text";
    const [value, setValue] = useState("");

    /**
     * @param {KeyboardEvent} e 
     */
    const handleKeyDown = e => {
        if (e.key === "Escape") {
            e.currentTarget.blur();
            setValue("");
            return;
        }
        if (e.key === "Enter") {
            context.sendMessage({
                type: MESSAGE.ADD_NOTE,
                text: value
            });
            setValue("");
            return;
        }
    }

    const showInput = !context.stateInfo.isAdmin && !context.stateInfo.isModerator;

    return (
        <div className={classes}>
            <CTriangleBox leftInner={true}>
                {showInput && <input 
                    type="text" 
                    maxLength={SETTINGS.NOTE_MAX_TEXT_LENGTH} 
                    value={value} 
                    className={"editable"} 
                    onKeyDown={handleKeyDown} 
                    onChange={e => setValue(e.currentTarget.value)} 
                />}
                <span>{value}</span> 
            </CTriangleBox>
        </div>
    );
}
