
/**
 * @typedef Info
 * @property {InfoElement[]} elements
 * 
 * @typedef InfoElement
 * @property {string} value
 * @property {INFO_TYPE} type
 * @property {boolean} [admin]
 * @property {boolean} [result]
 */

/**
 * @enum {string}
 */
export const INFO_TYPE = {
    TEXT: "text",
    IMAGE: "image_url",
    VIDEO: "video_url",
    AUDIO: "audio_url"
};