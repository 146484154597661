

export class Config {
    
    /**
     * Volume between 0 and 1.
     * @type {number}
     */
    volume;

    constructor() {
        this.volume = 0.5;
    }
}