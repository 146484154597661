import { GUIManager } from "../guiManager.js";
import { GUIObject } from "../guiObject.js";
import * as d3 from "d3";

import "../../styles/elements/scoreboard.css";


/**
 * @type {number}
 * @private
 */
const MAX_LENGTH_TEAMNAME = 50;

/**
 * @enum {string | (id: number) => string}
 * @private
 */
const ROLE = {
    /**
     * - See everything.
     * - Edit everything.
     */
    ADMIN: "admin",
    /**
     * - See everything.
     * - Edit nothing.
     */
    SPECTATOR: "spectator",
    /**
     * - See everything.
     * - Edit team with own id.
     * @param {number} id
     * @returns {string}
     */
    TEAM: id => "team" + id
}

/**
 * @enum {string}
 * @private
 */
const MESSAGE_TYPE = {
    /**
     * Change the name of a team.
     * Maximum length {@link MAX_LENGTH_TEAMNAME}.
     * Only {@link ROLE.ADMIN} or {@link ROLE.TEAM} for the own team.
     * @param {number} team_id
     * @param {number} team_name
     */
    TEAM_NAME_CHANGE: "team_name_change",
    /**
     * Set the score of a team.
     * Only {@link ROLE.ADMIN}
     * @param {number} team_id
     * @param {number} score
     */
    SCORE_SET: "score_set",
    /**
     * Change the score of a team.
     * Only {@link ROLE.ADMIN}
     * @param {number} team_id
     * @param {number} points
     */
    SCORE_CHANGE: "score_change"
}

/**
 * @type {string}
 * @private
 */
const HTML_SCOREBOARD = `
    <div id="scoreboard">
        <div id="host">
            <div class="scoreboard_team_outerContent"></div>
            <div class="scoreboard_team_innerContent"></div>
        </div>
    </div>
`;

/**
 * @type {string}
 * @private
 */
const HTML_TEAM = `
    <div class="scoreboard_team_outerContent"></div>
    <div class="scoreboard_team_innerContent">
        <div class="scoreboard_team_general">
            <div class="scoreboard_team_info">
                <div class="scoreboard_team_players"></div>
                <div class="scoreboard_team_name"></div>
            </div>
            <div class="scoreboard_team_score_div">
                <div class="scoreboard_team_score"></div>
                <div class="scoreboard_team_score_detail"></div>
                <div class="scoreboard_team_score_changes"></div>
                <div class="scoreboard_team_score_changer_div hidden">
                    <input class="scoreboard_team_score_changer" type="number"/>
                </div>
            </div>
        </div>
    </div>
`;


/**
 * classid "scoreboard"
 */
export class Scoreboard extends GUIObject {

    /**
     * @type {d3.Selection}
     */
    divScoreboard;

    /**
     * @param {GUIManager} guiManager 
     * @param {Object} message 
     */
    constructor(guiManager, message) {
        super(guiManager, message);

        d3.select("#game_scoreboard").html(HTML_SCOREBOARD);
        this.divScoreboard = d3.select("#scoreboard");

        this.update();
    }

    update() {
        let teamGeneralDivs = this.divScoreboard.selectAll(".scoreboard_team")
            .data(this.state.teams, d => d.id)
            .join(
                enter => {
                    let div = enter.append("div")
                        .attr("class", "scoreboard_team")
                        .html(HTML_TEAM);

                    let filterName = div.filter(d => (this.stateInfo.role == ROLE.ADMIN || this.stateInfo.role == ROLE.TEAM(d.id)));
                    filterName.select(".scoreboard_team_name")
                        .classed("editable", true)
                        .attr("contenteditable", true)
                        .on("keydown", (e, d) => {
                            e.stopPropagation();
                            if (e.key == "Escape") {
                                d3.select(e.currentTarget).text(d.name);
                                return;
                            }
                            if (e.key != "Enter") return;
                            e.preventDefault();
                            let text = d3.select(e.currentTarget).text();
                            if (text == d.name) return;
                            this.sendMessage({
                                type: MESSAGE_TYPE.TEAM_NAME_CHANGE,
                                team_id: d.id,
                                team_name: text
                            })
                        })
                        .on("focusout", (e, d) => {
                            let text = d3.select(e.currentTarget).text();
                            if (text == d.text) return;
                            this.sendMessage({
                                type: MESSAGE_TYPE.TEAM_NAME_CHANGE,
                                team_id: d.id,
                                team_name: text
                            })
                        });

                    let filterScore = div.filter(d => (this.stateInfo.role == ROLE.ADMIN));
                    filterScore.select(".scoreboard_team_score")
                        .classed("editable", true)
                        .on("click", /** @param {PointerEvent} e */ e => {
                            let target = d3.select(e.target.parentNode);
                            let changerDiv = target.select(".scoreboard_team_score_changer_div");
                            changerDiv.classed("hidden", false);
                            target.select(".scoreboard_team_score_changer")
                                .property("value", "")
                                .node().focus();
                        });
                    filterScore.select(".scoreboard_team_score_changer")
                        .on("keydown", /** @param {KeyboardEvent} e */ (e, d) => {
                            e.stopPropagation();
                            if (e.key != "Enter") return;
                            let target = d3.select(e.target);
                            let value = parseFloat(target.property("value"));
                            if (isNaN(value)) return;
                            d3.select(e.target.parentNode).classed("hidden", true);
                            this.sendMessage({
                                type: MESSAGE_TYPE.SCORE_CHANGE,
                                team_id: d.id,
                                points: value
                            })
                        })
                        .on("focusout", /** @param {FocusEvent} e */ e => {
                            let target = d3.select(e.target.parentNode);
                            target.classed("hidden", true);
                        });

                    div.select(".scoreboard_team_score")
                        .text(d => Math.round(d.score));
                    return div;
                },
                update => update
            )
            .call(d => d.select(".scoreboard_team_innerContent")) // sets data variables
            .call(d => d.select(".scoreboard_team_outerContent")) // sets data variables
            .select(".scoreboard_team_general");
        
        teamGeneralDivs.select(".scoreboard_team_players")
            .selectAll(".player")
            .data(d => d.players)
            .join(enter => enter.append("div")
                .attr("class", "player"))
            .text(d => d.name);
        teamGeneralDivs.select(".scoreboard_team_name")
            .text(d => d.name);

        teamGeneralDivs.select(".scoreboard_team_score_detail")
            .text(d => Math.round(d.score * 100) / 100);
        teamGeneralDivs.select(".scoreboard_team_score")
            .filter(d => this.oldState.teams.find(t => t.id == d.id).score != d.score)
            .each(d => {
                let parent = teamGeneralDivs.filter(d2 => d2.id == d.id);
                let old = this.oldState.teams.find(t => t.id == d.id).score
                let diff = Math.round(d.score - old);
                let diffDiv = parent.select(".scoreboard_team_score_changes").append("div")
                    .attr("class", "scoreboard_team_score_change")
                    .classed("positive", diff > 0)
                    .classed("negative", diff < 0)
                    .text(diff > 0 ? "+" + diff : diff)
                    .datum(d => {
                        d.old = old;
                        return d;
                    });
                diffDiv.on("animationend", () => diffDiv.remove());
            })
            .transition()
            .delay(1000)
            .duration(1500)
            .textTween(d => t => Math.round(d3.interpolate(d.old, d.score)(t)));
    }

    destroy() {
        this.divScoreboard.remove();
    }
}
