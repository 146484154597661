
import { GUIObject } from "../guiObject.js";
import * as d3 from "d3"

/**
 * @typedef State_Communication
 * @property {Object[]} streams
 * @property {number} streams[].player_id
 * @property {number} streams[].team_id
 * @property {Object[]} players
 * @property {number} players[].player_id
 * @property {number} players[].channel
 */


/**
 * @enum {number}
 */
const ROLE = {
    /**
     * - See everything.
     * - Own Webcam.
     */
    ADMIN: 1,
    /**
     * - See everything.
     * - No Webcam.
     */
    SPECTATOR: 2,
    /**
     * - See everything.
     * - Own Webcam.
     */
    TEAM: 3
}

/**
 * @enum {number}
 */
const MESSAGE_TYPE_SEND = {
    /**
     * Sends an offer.
     * @param {Object} sdp
     * @param {number} connection_id
     */
    SEND_OFFER: 1,
    /**
     * Sends an answer.
     * @param {Object} sdp
     * @param {number} connection_id
     */
    SEND_ANSWER: 2,
    /**
     * Send ice candidate.
     * @param {Object} candidate
     * @param {number} connection_id
     */
    SEND_ICE_CANDIDATE: 3,
    /**
     * Starts a WebRTC connection.
     * @param {number} connection_id
     */
    CONNECT: 4,
    /**
     * Starts a WebRTC connection for sending.
     */
    START_SENDING: 5
}

/**
 * @enum {number}
 */
const MESSAGE_TYPE_RECEIVE = {
    /**
     * Sends an offer.
     * @param {Object} sdp
     * @param {number} connection_id
     */
    SEND_OFFER: 1,
    /**
     * Sends an answer.
     * @param {Object} sdp
     * @param {number} connection_id
     */
    SEND_ANSWER: 2,
    /**
     * Send ice candidate.
     * @param {Object} candidate
     * @param {number} connection_id
     */
    SEND_ICE_CANDIDATE: 3
}

const ICE_CONFIGURATION = {
    iceServers: [
        {
            urls: 'turn:mindgame-quiz.de:3478',
            username: 'quiz',
            credential: 'Z*74tkA16jiR%'
        }
    ]
};

/**
 * classid "communication"
 * @extends GUIObject<State_Communication>
 */
export class Communication extends GUIObject {

    /**
     * @type {d3.Selection}
     */
    webcamsDivs;

    /**
     * @type {Map<number,RTCPeerConnection>}
     */
    connections;

    /**
     * @type {Map<number, MediaStream}
     */
    streams;

    /**
     * @param {GUIManager} guiManager 
     * @param {Object} message 
     */
    constructor(guiManager, message) {
        super(guiManager, message);
        
        this.streams = new Map();

        this.webcamsDivs = d3.selectAll(".scoreboard_team_innerContent").append("div")
            .attr("class", "webcams");

        this.connections = new Map();

        if (this.stateInfo.role == ROLE.ADMIN || this.stateInfo.role == ROLE.TEAM) {
            let div = this.stateInfo.role == ROLE.ADMIN ?
                this.webcamsDivs.filter(d => !d) :
                this.webcamsDivs.filter(d => d && d.id == this.stateInfo.team_id);
            navigator.mediaDevices.getUserMedia({ video: true }).then(() => {
                navigator.mediaDevices.enumerateDevices().then(devices => {
                    let sources = div.append("div")
                        .attr("class", "webcam_sources");
                    devices.forEach(device => {
                        if (device.kind != "videoinput") return;
                        sources.append("div")
                            .text(device.label)
                            .on("click", () => {
                                sources.remove();
                                navigator.mediaDevices.getUserMedia({
                                    video: { deviceId: { exact: device.deviceId }, width: 320, height: 240, frameRate: 30 }
                                }).then(stream => {
                                    let id = this.stateInfo.player_id;
                                    let connection = this.newConnection(id);
                                    connection.addTrack(stream.getVideoTracks()[0], stream);
                                    let parameters = connection.getSenders()[0].getParameters();
                                    parameters.encodings[0].maxBitrate = 50000;
                                    parameters.encodings[0].scaleResolutionDownBy = 1;
                                    parameters.encodings[0].maxFramerate = 15;
                                    connection.getSenders()[0].setParameters(parameters);
                                    this.streams.set(id, stream);
                                    this.updateStreams();
                                });
                            });
                    });
                });
            });
        }
    }

    newConnection(id) {
        if (id == this.stateInfo.player_id) {
            this.sendMessage({
                type: MESSAGE_TYPE_SEND.START_SENDING
            });
        } else {
            this.sendMessage({
                type: MESSAGE_TYPE_SEND.CONNECT,
                connection_id: id
            });
        }
        let connection = new RTCPeerConnection(ICE_CONFIGURATION);
        connection.onicecandidate = event => {
            if (!event.candidate || event.candidate.length == 0) return;
            this.sendMessage({
                type: MESSAGE_TYPE_SEND.SEND_ICE_CANDIDATE,
                candidate: event.candidate,
                connection_id: id
            });
        };
        connection.onnegotiationneeded = () => {
            connection.createOffer().then(offer => {
                connection.setLocalDescription(offer).then(() => {
                    this.sendMessage({
                        type: MESSAGE_TYPE_SEND.SEND_OFFER,
                        sdp: offer,
                        connection_id: id
                    });
                });
            });
        };
        connection.ontrack = event => {
            event.streams.forEach(stream => {
                this.streams.set(id, stream);
                this.updateStreams();
            });
        };

        this.connections.set(id, connection);

        return connection;
    }

    destroy() {
        this.connections.forEach(c => c.close());
        this.webcamsDivs.remove();
    }

    update() {
        this.state.streams.filter(s => !this.connections.has(s.player_id)).forEach(s => this.newConnection(s.player_id));
        this.connections.forEach((c, id) => {
            if (!this.state.streams.some(s => s.player_id == id)) {
                c.close();
                this.connections.delete(id);
            }
        });

        this.updateStreams();
    }

    updateStreams() {
        let data = new Map();
        this.state.streams.forEach(s => {
            if (!this.streams.has(s.player_id)) return;
            if (!data.has(s.team_id)) data.set(s.team_id, []);
            data.get(s.team_id).push({
                player_id: s.player_id,
                team_id: s.team_id,
                stream: this.streams.get(s.player_id),
                channel: this.state.players.find(p => p.player_id == s.player_id).channel
            });
        });
        data.forEach(v => {
            v.forEach(p => p.size_team = v.length);
        });

        let ownPlayer = this.state.players.find(p => p.player_id == this.stateInfo.player_id);
        let ownChannel = ownPlayer ? ownPlayer.channel : -1;

        let videoDivs = this.webcamsDivs.selectAll(".videoDiv")
            .data(d => d ?
                (data.has(d.id) ? data.get(d.id) : []) :
                (data.has(-1) ? data.get(-1) : []),
                d => d.player_id)
            .join(enter => enter.append("div")
                .attr("class", "videoDiv")
                .call(div => div.append("video"))
            )
            .style("width", (d, i, a) => a[i].parentNode.offsetWidth / d.size_team);

        videoDivs.classed("other_channel", d => d.channel != ownChannel);

        videoDivs.select("video")
            .attr("width", (d, i, a) => a[i].parentNode.parentNode.offsetWidth / d.size_team)
            .on("loadedmetadata", e => e.target.play())
            .filter(function(d) {
                return d3.select(this).property("srcObject") != d.stream;
            })
            .property("srcObject", d => d.stream);
    }

    answer(offer, connection_id) {
        let connection = this.connections.get(connection_id);
        connection.setRemoteDescription(offer).then(() => {
            connection.createAnswer().then(answer => {
                connection.setLocalDescription(answer).then(() => {
                    this.sendMessage({
                        type: MESSAGE_TYPE_SEND.SEND_ANSWER,
                        sdp: answer,
                        connection_id: connection_id
                    });
                });
            });
        });
    }

    handleMessage(message) {
        switch (message.all.type) {
            case MESSAGE_TYPE_RECEIVE.SEND_OFFER:
                this.answer(message.all.sdp, message.all.connection_id);
                break;
            case MESSAGE_TYPE_RECEIVE.SEND_ANSWER:
                this.connections.get(message.all.connection_id).setRemoteDescription(message.all.sdp);
                break;
            case MESSAGE_TYPE_RECEIVE.SEND_ICE_CANDIDATE:
                this.connections.get(message.all.connection_id).addIceCandidate(message.all.candidate);
                break;
        }
    }
}