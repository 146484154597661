import { GUIManager } from "../guiManager.js";
import { GUIObject } from "../guiObject.js";
import { Question } from "./question.js";
import * as d3 from "d3"

/**
 * @typedef State_Multiquestion
 * @property {PHASE} phase
 * @property {number} activeSubquestionIndex
 * @property {number} num_subquestions
 */

/**
 * @enum {number}
 * @private
 */
const PHASE = {
    /**
     * Question selection animation.
     */
    START: 1,
    /**
     * Questions.
     */
    QUESTION: 2
}


/**
 * classid "multiquestion"
 * @template {State_Multiquestion} S
 * @extends Question<S>
 */
export class Multiquestion extends Question {

    /**
     * Div for all DOM elements of the multiquestion
     * @type {d3.Selection}
     */
    divMultiquestion;

    /**
     * name of the roles with admin rights
     * @type {string[]}
     */
    admin_roles;

    /**
     * 
     * @param {GUIManager} guiManager 
     * @param {*} message 
     * @param {(string | number)[]} admin_roles 
     */
    constructor(guiManager, message, admin_roles) {
        super(guiManager, message);

        this.admin_roles = admin_roles;

        d3.select("#question_content").html(HTML_MULTIQUESTION);
        this.divMultiquestion = d3.select("#multiquestion");

        if (this.admin_roles.includes(this.stateInfo.role)) {
            this.divMultiquestion.select("#multiquestion_controls").classed("hidden", false);
        }
    }

    destroy() {
        this.divMultiquestion.remove();
        super.destroy();
    }

    update() {
        super.update();

        this.questionDiv.classed("start", this.state.phase == PHASE.START);

        if (this.admin_roles.includes(this.stateInfo.role)) {
            this.divMultiquestion.select("#multiquestion_controls")
                .selectAll(".subquestion_selector")
                .data(Array(this.state.num_subquestions).fill(-1).map((_, i) => i))
                .join("div")
                .attr("class", "subquestion_selector")
                .classed("active", d => this.state.activeSubquestionIndex == d)
                .html(d => (d+1))
                .on("click", (_, d) => {
                    let message;
                    if (this.state.activeSubquestionIndex == d) {
                        message = {
                            type: "hide_subquestions"
                        };
                    } else {
                        message = {
                            type: "show_subquestion",
                            index: d
                        };
                    }
                    this.sendMessage(message);
                });
        }
    }
}

/**
 * @private
 */
const HTML_MULTIQUESTION = `
<div id="multiquestion">
    <div id="multiquestion_content">
    </div>
    <div id="multiquestion_controls" class="hidden">
    </div>
</div>
`;


/**
 * classid "subquestion"
 * @template S
 * @extends GUIObject<S>
 */
export class Subquestion extends GUIObject {

    /**
     * Div for all DOM elements of the subquestion
     * @type {d3.Selection}
     */
    divSubquestion;

    /**
     * 
     * @param {GUIManager} guiManager 
     * @param {*} message 
     */
    constructor(guiManager, message) {
        super(guiManager, message);

        d3.select("#multiquestion_content").html(HTML_SUBQUESTION);
        this.divSubquestion = d3.select("#subquestion");
    }

    destroy() {
        this.divSubquestion.remove();
        super.destroy();
    }
}

/**
 * @private
 */
const HTML_SUBQUESTION = `<div id="subquestion"></div>`;