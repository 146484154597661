import { MESSAGE_GAME_FROM, MESSAGE_GAME_TO, ROLE_GAME, SETTINGS_GAME } from "./definitions/game.defs.js";
import { GUIObject } from "./guiObject.js";
import * as d3 from "d3";

import "../styles/game.css";

/**
 * @extends GUIObject<import("./definitions/game.defs.js").State_Game,import("./definitions/game.defs.js").StateInfo_Game>
 */
export class Game extends GUIObject {

    /**
     * Start timestamp.
     * @type {number}
     */
    startTimestamp;

    /**
     * Time at {@link startTimestamt}.
     * @type {number}
     */
    time;


    constructor(guiManager, message) {
        super(guiManager, message);

        d3.select("#app").html(HTML);

        this.startTimestamp = Date.now();
        this.time = message.all.time;
        this.startUpdateTime();

        if (this.stateInfo.role == ROLE_GAME.ADMIN) {
            d3.select("#game_controls").classed("hidden", false);
        }

        // * Volume Slider
        d3.select("#game_volume input")
            .property("value", this.config.volume)
            .on("change", e => {
                let value = d3.select(e.currentTarget).property("value");
                this.config.volume = Math.min(1, Math.max(0, value));
                let audio = new Audio("./audio/volume_test.mp3");
                audio.volume = this.config.volume;
                audio.play();
            });
    }

    initFinished() {
        this.sendPing();
    }

    update() {
        if (this.stateInfo.role == ROLE_GAME.ADMIN) {
            // * Playermenu
            d3.select("#game_controls_players_table").selectAll(".game_controls_player")
                .data(this.state.players, d => d.player_id)
                .join(this.enterPlayer.bind(this))
                .call(this.updatePlayer.bind(this));
        }
        
    }

    /**
     * @param {d3.Selection<d3.BaseType, import("./definitions/game.defs.js").State_Game_Player, d3.BaseType, any>} enter 
     * @returns {d3.Selection<d3.BaseType, import("./definitions/game.defs.js").State_Game_Player, d3.BaseType, any>}
     */
    enterPlayer(enter) {
        let div = enter.append("tr")
            .attr("class", "game_controls_player");

        // * Admin button
        div.append("td")
            .attr("class", "game_controls_player_adminchanger")
            .text("A")
            .on("click", (_, d) => this.sendMessage({
                type: MESSAGE_GAME_TO.SET_ADMIN,
                player_id: d.player_id,
                admin: !d.admin
            }));
        
        // * Moderator button
        div.append("td")
            .attr("class", "game_controls_player_moderatorchanger")
            .text("M")
            .on("click", (_, d) => this.sendMessage({
                type: MESSAGE_GAME_TO.SET_MODERATOR,
                player_id: d.player_id,
                moderator: !d.moderator
            }));
            
        // * Team
        let teamdiv = div.append("td")
            .attr("class", "game_controls_player_team");
        teamdiv.append("div")
            .attr("class", "game_controls_player_teamselector hidden");
        teamdiv.append("div")
            .attr("class", "game_controls_player_teamnumber")
            .on("click", (_, d) => {
                let elements = d3.selectAll(".game_controls_player_teamselector");
                let element = elements.filter(dc => dc.player_id == d.player_id);
                let oldState = element.classed("hidden");
                elements.classed("hidden", true);
                element.classed("hidden", !oldState);
            });

        // * Player name
        div.append("td")
            .attr("class", "game_controls_player_name");

        // * Reload button
        div.append("td")
            .attr("class", "game_controls_player_reload")
            .text("R")
            .on("click", (_, d) => this.sendMessage({
                type: MESSAGE_GAME_TO.RELOAD,
                player_id: d.player_id
            }));

        // * Kick button
        div.append("td")
            .attr("class", "game_controls_player_kick")
            .text("X")
            .on("click", (_, d) => this.sendMessage({
                type: MESSAGE_GAME_TO.KICK,
                player_id: d.player_id
            }));

        return div;
    }

    /**
     * @param {d3.Selection<d3.BaseType, import("./definitions/game.defs.js").State_Game_Player, d3.BaseType, any>} update 
     */
    updatePlayer(update) {

        // * Admin button
        update.select(".game_controls_player_adminchanger")
            .classed("active", d => d.admin);

        // * Moderator button
        update.select(".game_controls_player_moderatorchanger")
            .classed("active", d => d.moderator);

        // * Team
        update.select(".game_controls_player_team > .game_controls_player_teamnumber")
            .text(d => d.team_id < 0 ? "-" : d.team_id);
        let teams = this.state.team_ids.map(id => id);
        teams.push(-1);
        update.select(".game_controls_player_teamselector").selectAll(".game_controls_player_teamnumber")
            .data(d => d.admin ? [] : teams.filter(id => id != d.team_id && (id >= 0 || d.team_id >= 0)).map(id => ({
                team_id: id,
                player_id: d.player_id
            })))
            .join(enter => enter.append("div")
                .attr("class", "game_controls_player_teamnumber")
                .on("click", (_, d) => {
                    d3.selectAll(".game_controls_player_teamselector")
                        .classed("hidden", true);
                    this.sendMessage({
                        type: MESSAGE_GAME_TO.SET_TEAM,
                        player_id: d.player_id,
                        team_id: d.team_id
                    });
                })
            )
            .text(d => d.team_id < 0 ? "-" : d.team_id);

        // * Player name
        update.select(".game_controls_player_name")
            .text(d => d.name);
    }

    destroy() {
        d3.select("#game").remove();
    }

    sendPing() {
        this.ping_timestamp = Date.now();
        this.sendMessage({
            type: MESSAGE_GAME_TO.PING
        });
    }

    handlePing() {
        let ping = Date.now() - this.ping_timestamp;
        d3.select("#game_ping").text(ping + " ms");
        setTimeout(this.sendPing.bind(this), SETTINGS_GAME.PING_RATE);
    }

    startUpdateTime() {
        let timePassed = (Date.now() - this.startTimestamp) / 1000;
        let seconds = (timePassed + this.time) * 1000;
        d3.select("#game_time").text(new Date(seconds).toISOString().substring(11, 19));
        setTimeout(this.startUpdateTime.bind(this), 1 - (seconds - Math.floor(seconds)) * 1000);
    }

    handleMessage(message) {
        switch (message.all.type) {
            case MESSAGE_GAME_FROM.PING:
                this.handlePing();
            default:
                break;
        }
    }
}

const HTML = `
    <div id="game">
        <div id="game_controls" class="hidden">
            <div id="game_controls_fade"></div>
            <div id="game_controls_players">
                <table id="game_controls_players_table"></table>
            </div>
        </div>
        <div id="game_header">
            <div id="game_header_content">
                <div id="game_volume">
                    <span>Lautstärke</span>
                    <input type="range" min="0" max="1" value="0.5" class="slider" step="0.01">
                </div>
                <div id="game_time"></div>
                <div id="game_ping"></div>
            </div>
        </div>
        <div id="game_content">

        </div>
        <div id="game_scoreboard">

        </div>
    </div>
`;