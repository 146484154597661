import { Timer } from "../elements/timer.js";
import { GUIManager } from "../guiManager.js";
import { Subquestion_Clue } from "./clue.js";
import * as d3 from "d3"

/**
 * @enum {string}
 */
const ROLE = {
    ADMIN: 1,
    PLAYER: 2
}

/**
 * @typedef {Object} Subquestion_ClueDialog_State
 * @property {PHASE} phase
 * @property {Object[]} hints
 * @property {number} hints[].person_id
 * @property {string} [hints[].hint]
 * @property {boolean} hints[].visible
 * @property {boolean} buzzed
 * @property {boolean} result_visible
 * @property {string} result
 * @property {string[]} persons
 * @property {number} max_text_length
 */


/**
 * classid "subquestion_clue_dialog"
 * @extends Subquestion_Clue<Subquestion_ClueDialog_State>
 * @abstract
 */
export class Subquestion_ClueDialog extends Subquestion_Clue {

    /**
     * 
     * @param {GUIManager} guiManager 
     * @param {*} message 
     */
    constructor(guiManager, message) {
        super(guiManager, message);

        this.divHints.classed("dialog", true);
    }

    update() {
        super.update();

        let data = this.state.hints.map((h, i, a) => ({
            hint: h.hint,
            visible: h.visible,
            timer: !h.visible && (i > 0 ? a[i-1].visible : true)
        }));

        this.divHints.selectAll(".hint")
            .data(data)
            .join(enter => enter.append("div")
                .attr("class", "hint")
                .call(div => div.append("div"))
            )
            .classed("halfvisible", d => !d.visible && this.stateInfo.role == ROLE.ADMIN)
            .style("width", this.state.max_text_length + "em")
            .call(div => div.select("div")
                .attr("id", d => (d.timer ? "timer_clue_dialog" : null))
                .text(d => d.hint)
            );
        this.divResult
            .select("div")
                .classed("rotateOut", !this.state.result_visible && this.stateInfo.role == ROLE.PLAYER)
                .classed("halfvisible", !this.state.result_visible && this.stateInfo.role == ROLE.ADMIN)
                .select("span")
                    .html(this.state.result);
    }
}

/**
 * classid "timer_clue_dialog"
 */
export class Timer_ClueDialog extends Timer {
    constructor(guiManager, message) {
        super(guiManager, message, "#timer_clue_dialog");

        this.svg.attr("viewBox", "0 0 100 10");
        this.path.attr("d", "M 5, 5 l 90, 0");
    }

    updateComponents() {
        super.updateComponents();

        let pathFraction = this.displayTime / this.state.time_full * 90;
        let offset = -(90 - pathFraction) / 2;
        this.path.attr("stroke-dasharray", pathFraction + " 90");
        this.path.attr("stroke-dashoffset", offset);
    }
}