
export const SETTINGS_QUESTION_SORT = {
    NOTE_TEXT_LENGTH: 50,
    DEFAULT_TIMER_PREPARATION: 180,
    DEFAULT_TIMER_ANSWER: 20,
    DEFAULT_ELEMENT_START: -1,
    DEFAULT_START_VALUE_VISIBLE: true,
    DEFAULT_VALUES_VISIBLE: false
};

/**
 * @typedef Config_Question_Sort
 * @property {Object[]} elements
 * @property {string} [elements[].name]
 * @property {string} [elements[].img_url]
 * @property {string | number} elements[].value
 * @property {import("../elements/info.defs").Info} elements[].info
 * 
 * @property {string} [order_top]
 * @property {string} [order_bottom]
 * 
 * @property {number} [element_start] element_index, -1 for a random element, defaults to {@link SETTINGS_QUESTION_SORT.DEFAULT_ELEMENT_START}
 * @property {boolean} [element_start_value_visible] defaults to {@link SETTINGS_QUESTION_SORT.DEFAULT_START_VALUE_VISIBLE}
 * @property {boolean} [values_visible] all values visible after the position is revealed, defaults to {@link SETTINGS_QUESTION_SORT.DEFAULT_VALUES_VISIBLE}
 * 
 * @property {number} [timer_preparation] defaults to {@link SETTINGS_QUESTION_SORT.DEFAULT_TIMER_PREPARATION}
 * @property {number} [timer_answer] defaults to {@link SETTINGS_QUESTION_SORT.DEFAULT_TIMER_ANSWER}
 * @property {number} [mistakes] defaults to 1
 */

/**
 * @typedef State_Question_Sort
 * @property {PHASE_QUESTION_SORT} phase
 * 
 * @property {string} [order_top]
 * @property {string} [order_bottom]
 * 
 * @property {State_Question_Sort_Element[]} elements
 * 
 * @property {number} elements_max_name_length
 * @property {number} elements_max_value_length
 * 
 * @property {number[]} order element_ids, only ids with elements.position_visible are visible for all, from low to high
 * 
 * @property {Object} [answer]
 * @property {number} answer.element_id 
 * @property {number} answer.position position in order with only ids with elements.position_visible
 * 
 * @property {number} active_team_id -1 if no team is active, only in {@link PHASE_QUESTION_SORT.ANSWERS}
 *
 * @property {Object[]} teams
 * @property {number} teams[].id
 * @property {State_Question_Sort_Note[]} teams[].notes
 * 
 * @property {number} moderation_team_id -1 if no team view is active
 */

/**
 * @typedef State_Question_Sort_Element
 * @property {number} id
 * @property {boolean} visible if false, only id is visible for all
 * @property {string} [name]
 * @property {string} [img_url]
 * @property {string} [value]
 * @property {boolean} value_visible
 * @property {boolean} position_visible
 * @property {boolean} info_result_visible
 * @property {import("../elements/info.defs").Info} [info]
 */

/**
 * @typedef State_Question_Sort_Note
 * @property {number} element_id
 * @property {boolean} fixed true if the position matches the correct order
 * @property {boolean} suggested only one element can be suggested
 * @property {TAG_QUESTION_SORT} tag
 * @property {string} text max length {@link SETTINGS_QUESTION_SORT.NOTE_TEXT_LENGTH}
 */

/**
 * @typedef StateInfo_Question_Sort
 * @property {ROLE_QUESTION_SORT} role
 * @property {number} [team_id] only for {@link ROLE_QUESTION_SORT.TEAM}
 */

/**
 * @enum {number}
 */
export const TAG_QUESTION_SORT = {
    NONE: 0,
    SURE: 1,
    UNSURE: 2
};

/**
 * @enum {number}
 */
export const ROLE_QUESTION_SORT = {
    ADMIN: 1,
    MODERATOR: 2,
    TEAM: 3,
    SPECTATOR: 4
};

/**
 * @enum {number}
 */
export const MESSAGE_QUESTION_SORT = {
    /**
     * Starts next Phase (if matches). 
     * Only {@link ROLE_QUESTION_SORT.ADMIN}. 
     * @param {PHASE} next_phase
     */
    NEXT_PHASE: 1,
    /**
     * Shows an element. 
     * Only {@link ROLE_QUESTION_SORT.ADMIN}. 
     * @param {number} element_id
     */
    SHOW_ELEMENT: 2,
    /**
     * Shows all elements. 
     * Only {@link ROLE_QUESTION_SORT.ADMIN}. 
     */
    SHOW_ELEMENTS: 3,
    /**
     * Give an answer. 
     * Only {@link ROLE_QUESTION_SORT.ADMIN} and {@link ROLE_QUESTION_SORT.TEAM} if the active team. 
     * @param {number} element_id
     * @param {number} position
     */
    ANSWER: 4,
    /**
     * Revert the given answer. 
     * Only {@link ROLE_QUESTION_SORT.ADMIN}. 
     */
    REVERT_ANSWER: 5,
    /**
     * Evaluate the given answer. 
     * Only {@link ROLE_QUESTION_SORT.ADMIN}. 
     */
    EVALUATE_ANSWER: 6,
    /**
     * Evaluate the given answer as false. 
     * Only {@link ROLE_QUESTION_SORT.ADMIN}. 
     */
    ANSWER_TOO_LATE: 7,
    /**
     * Show the position of an element. 
     * Only {@link ROLE_QUESTION_SORT.ADMIN}. 
     * @param {number} element_id
     */
    ORDER_ELEMENT: 8,
    /**
     * Show the position of all elements. 
     * Only {@link ROLE_QUESTION_SORT.ADMIN}. 
     */
    ORDER_ELEMENTS: 9,
    /**
     * Show the value of an element. 
     * Only {@link ROLE_QUESTION_SORT.ADMIN}. 
     * @param {number} element_id
     */
    SHOW_VALUE: 10,
    /**
     * Show the values of all elements. 
     * Only {@link ROLE_QUESTION_SORT.ADMIN}. 
     */
    SHOW_VALUES: 11,
    /**
     * Show the info of an element. 
     * Only {@link ROLE_QUESTION_SORT.ADMIN}. 
     * @param {number} element_id
     */
    SHOW_INFO: 12,
    /**
     * Show the infos of all elements. 
     * Only {@link ROLE_QUESTION_SORT.ADMIN}.
     */
    SHOW_INFOS: 13,
    /**
     * Set the oder of an elements for the own team notes. 
     * Dont do something if number_elements not matching. 
     * Only {@link ROLE_QUESTION_SORT.TEAM}. 
     * @param {number} element_id
     * @param {number} position
     * @param {number} number_elements
     */
    NOTE_ORDER: 14,
    /**
     * Set an elements as suggested for the own team notes. 
     * Only {@link ROLE_QUESTION_SORT.TEAM}. 
     * @param {number} element_id
     */
    NOTE_SUGGEST: 15,
    /**
     * Set the tag of an elements for the own team notes. 
     * Only {@link ROLE_QUESTION_SORT.TEAM}. 
     * @param {number} element_id
     * @param {TAG_QUESTION_SORT} tag
     */
    NOTE_TAG: 16,
    /**
     * Set the tag of an elements for the own team notes. 
     * Only {@link ROLE_QUESTION_SORT.TEAM}. 
     * @param {number} element_id
     * @param {string} text max length {@link SETTINGS_QUESTION_SORT.NOTE_TEXT_LENGTH}
     */
    NOTE_TEXT: 17,
    /**
     * Change the moderated team. 
     * Only {@link ROLE_QUESTION_SORT.MODERATOR}. 
     * @param {number} team_id -1 if no team view is active
     */
    MODERATED_TEAM: 18
};

/**
 * @enum {number}
 */
export const PHASE_QUESTION_SORT = {
    START: 1,
    QUESTION: 2,
    PREPARATION: 3,
    PREPARATION_END: 4,
    ANSWERS: 5,
    RESULTS: 6,
    FINISHED: 7
};

export const CLASSID_QUESTION_SORT = "question_sort";