import { GUIObject } from "../guiObject.js";
import * as d3 from "d3"

/**
 * @typedef State_Buzzer
 * @property {Object[]} buzzerQueue
 * @property {string} buzzerQueue[].playername
 * @property {number} buzzerQueue[].teamid
 * @property {number} buzzerQueue[].time
 * @property {Object[]} teams
 * @property {number} teams[].id
 * @property {boolean} teams[].blocked
 */

/**
 * @enum {number}
 */
const ROLE = {
    /**
     * - See everything.
     * - Edit everything.
     */
    ADMIN: 1,
    /**
     * - See everything.
     * - Edit nothing.
     */
    SPECTATOR: 2,
    /**
     * - See everything.
     * - Edit nothing.
     */
    TEAM: 3
}

/**
 * classid "buzzer"
 * @extends GUIObject<State_Buzzer>
 */
export class Buzzer extends GUIObject {

    /**
     * @type {d3.Selection}
     */
    buzzerDivs;

    /**
     * @type {?boolean}
     */
    canBuzz;


    constructor(guiManager, message) {
        super(guiManager, message);

        this.buzzerDivs = d3.selectAll(".scoreboard_team").select(".scoreboard_team_outerContent").append("div")
            .attr("class", "buzzer")
            .html(HTML);

        if (this.stateInfo.role == ROLE.TEAM) {
            this.buzzerDivs.filter(d => d.id == this.stateInfo.team_id)
                .classed("ownTeam", true)
                .on("click", () => {
                    if (!this.canBuzz) return;
                    this.sendMessage({
                        type: "buzz"
                    });
                });
            d3.select("body").on("keydown", e => {
                if (e.code != "Space") return;
                if (!this.canBuzz) return;
                this.sendMessage({
                    type: "buzz"
                });
            });
        }

        this.update();
    }

    destroy() {
        this.buzzerDivs.remove();
        if (this.stateInfo.role == ROLE.TEAM) {
            d3.select("body").on("keydown", null);
        }
    }

    update() {
        if (this.oldState.buzzerQueue.length == 0 && this.state.buzzerQueue.length > 0) {
            let audio = new Audio("./audio/buzzer.mp3");
            audio.volume = this.config.volume;
            audio.play();
        }

        this.buzzerDivs
            .classed("blocked", d => this.state.teams.find(t => t.id == d.id).blocked)
            .classed("firstBuzzed", d => this.state.buzzerQueue.length > 0 && this.state.buzzerQueue[0].teamid == d.id)
            .classed("buzzered", d => this.state.buzzerQueue.some(b => b.teamid == d.id));

        if (this.stateInfo.role == ROLE.TEAM) {
            this.canBuzz = !this.state.teams.find(t => t.id == this.stateInfo.team_id).blocked && !this.state.buzzerQueue.some(b => b.teamid == this.stateInfo.team_id);
        }

        this.buzzerDivs.select(".buzzed_name")
            .text(d => {
                let buzz = this.state.buzzerQueue.find(b => b.teamid == d.id);
                return buzz ? buzz.playername : "";
            });
        this.buzzerDivs.select(".buzzed_time")
            .text(d => {
                let buzz = this.state.buzzerQueue.find(b => b.teamid == d.id);
                if (!buzz) return "";
                let diff = buzz.time - this.state.buzzerQueue[0].time;
                return diff == 0 ? "" : "+ " + Math.floor(diff / 1000) + "s " + diff % 1000 + "ms";
            });
    }
}

const HTML = `
<div class="buzzer_state"></div>
<div class="buzzed">
    <div class="buzzed_name"></div>
    <div class="buzzed_time"></div>
</div>
`;