import { Quiz } from "./quiz.js";
import { Game } from "./game.js";
import { Login } from "./login.js";
import { Scoreboard } from "./elements/scoreboard.js";
import { Question_List } from "./questions/list.js";
import { RoundManager } from "./elements/roundManager.js";
import { Timer } from "./elements/timer.js";
import { QuestionSelectionPairs } from "./quiz/questionSelectionPairs.js";
import { Question_Estimate, Question_Estimation_Admin, Question_Estimation_Input, Question_Estimation_Result } from "./questions/estimate.js";
import { Buzzer } from "./elements/buzzer.js";
import { Question_Assign } from "./questions/assign.js";
import { QuestionSelectionScrollinglist } from "./quiz/questionSelectionScrollinglist.js";
import { Subquestion_ClueText, Timer_ClueText } from "./questions/clueText.js";
import { Question_Clue } from "./questions/clue.js";
import { Timer_ClueEmoji, Subquestion_ClueEmoji } from "./questions/clueEmoji.js";
import { Subquestion_ClueCharacteristics, Timer_ClueCharacteristics } from "./questions/clueCharacteristics.js";
import { Subquestion_ClueDialog, Timer_ClueDialog } from "./questions/clueDialog.js";
import { Config } from "./config.js";
import { Communication } from "./elements/communication.js";
import { Mindgame } from "./elements/mindgame.js";
import { Voting_Buzzer } from "./elements/voting.js";
import { Bet_Pool } from "./elements/bet.js";
import { Question_Sort } from "./questions/sort.js";
import { GUIObject } from "./guiObject.js";
import { CLASSID_HEADER } from "./definitions/questions/question.defs.js";
import { QuestionHeader } from "./questions/question.js";


export class GUIManager {

    constructor(websocket) {
        this.websocket = websocket;
        this.websocket.onmessage = (message) => this.handleMessage(JSON.parse(message.data));
        this.idsByGuiObject = new Map();
        this.guiObjectsById = new Map();

        this.config = new Config();
    }

    createGuiObject(guiid, classid, message) {
        let guiObject;
        switch (classid) {
            case "login":
                guiObject = new Login(this, message);
                break;
            case "game":
                guiObject = new Game(this, message);
                break;
            case "quiz":
                guiObject = new Quiz(this, message);
                break;
            case "scoreboard":
                guiObject = new Scoreboard(this, message);
                break;
            case CLASSID_HEADER:
                guiObject = new QuestionHeader(this, message);
                break;
            case "question_list":
                guiObject = new Question_List(this, message);
                break;
            case "roundmanager":
                guiObject = new RoundManager(this, message);
                break;
            case "timer":
                guiObject = new Timer(this, message);
                break;
            case "question_selection_pairs":
                guiObject = new QuestionSelectionPairs(this, message);
                break;
            case "question_selection_scrollinglist":
                guiObject = new QuestionSelectionScrollinglist(this, message);
                break;
            case "question_estimate":
                guiObject = new Question_Estimate(this, message);
                break;
            case "question_estimate_estimation_admin":
                guiObject = new Question_Estimation_Admin(this, message);
                break;
            case "question_estimate_estimation_input":
                guiObject = new Question_Estimation_Input(this, message);
                break;
            case "question_estimate_estimation_result":
                guiObject = new Question_Estimation_Result(this, message);
                break;
            case "question_assign":
                guiObject = new Question_Assign(this, message);
                break;
            case "buzzer":
                guiObject = new Buzzer(this, message);
                break;
            case "communication":
                guiObject = new Communication(this, message);
                break;
            case "question_clue":
                guiObject = new Question_Clue(this, message);
                break;
            case "subquestion_clue_text":
                guiObject = new Subquestion_ClueText(this, message);
                break;
            case "timer_clue_text":
                guiObject = new Timer_ClueText(this, message);
                break;
            case "subquestion_clue_characteristics":
                guiObject = new Subquestion_ClueCharacteristics(this, message);
                break;
            case "timer_clue_characteristics":
                guiObject = new Timer_ClueCharacteristics(this, message);
                break;
            case "subquestion_clue_dialog":
                guiObject = new Subquestion_ClueDialog(this, message);
                break;
            case "timer_clue_dialog":
                guiObject = new Timer_ClueDialog(this, message);
                break;
            case "subquestion_clue_emoji":
                guiObject = new Subquestion_ClueEmoji(this, message);
                break;
            case "timer_clue_emoji":
                guiObject = new Timer_ClueEmoji(this, message);
                break;
            case "mindgame":
                guiObject = new Mindgame(this, message);
                break;
            case "voting_buzzer":
                guiObject = new Voting_Buzzer(this, message);
                break;
            case "bet_pool":
                guiObject = new Bet_Pool(this, message);
                break;
            case "question_sort":
                guiObject = new Question_Sort(this, message);
                break;
            default:
                break;
        }
        this.idsByGuiObject.set(guiObject, guiid);
        this.guiObjectsById.set(guiid, guiObject);
        guiObject.initFinished();
        guiObject.update();
    }

    /**
     * @returns {Config}
     */
    getConfig() {
        return this.config;
    }

    /**
     * @template {GUIObject} T
     * @param {new() => T} type 
     * @return {T}
     */
    getGuiObjectByType(type) {
        for (let e of this.idsByGuiObject.keys()) {
            if (e instanceof type) return e;
        }
        return null;
    }

    handleMessage(message) {
        console.log("RECEIVE");
        console.log(message);
        switch (message.type) {
            case "create":
                this.createGuiObject(message.guiid, message.classid, message);
                break;
            case "clear":
                let guiObject = this.guiObjectsById.get(message.guiid);
                this.guiObjectsById.delete(message.guiid);
                this.idsByGuiObject.delete(guiObject);
                guiObject.destroy();
                break;
            case "update":
                this.guiObjectsById.get(message.guiid).handleMessage(message);
                break;
            case "patch_state":
                this.guiObjectsById.get(message.guiid).patchState(message);
                break;
            default:
                break;
        }
    }

    sendMessage(guiObject, message) {
        message.guiid = this.idsByGuiObject.get(guiObject);
        if (!message.guiid) {
            return;
        }
        console.log("SEND");
        console.log(message); //debug
        this.websocket.send(JSON.stringify(message));
    }
}