import { GUIObject } from "./guiObject.js";
import * as d3 from "d3";

import "../styles/quiz.css";

/**
 * @typedef {Object} State_Quiz
 * @property {Object[]} content
 * @property {number} content[].id
 * @property {string} content[].selector_name
 * @property {boolean} content[].active
 */

/**
 * @enum {string}
 */
const ROLE = {
    /**
     * - See content.
     * - Edit everything.
     */
    ADMIN: 1,
    /**
     * - Dont see content.
     * - Edit nothing.
     */
    NOADMIN: 2
}

/**
 * @enum {() => Object}
 */
const MESSAGE_TYPE = {
    /**
     * Closes all content.
     * Only {@link ROLE.ADMIN}
     */
    CLOSE_ALL_CONTENT: () => { return {
        type: 1
    }},
    /**
     * Changes the active content.
     * Only {@link ROLE.ADMIN}
     * @param {number} id
     */
    CHANGE_ACTIVE_CONTENT: (id) => { return {
        type: 2,
        id: id
    }}
}

/**
 * classid "quiz"
 * @extends GUIObject<State_Quiz>
 */
export class Quiz extends GUIObject {

    /**
     * @type {d3.Selection}
     */
    quizDiv;

    /**
     * @type {d3.Selection}
     */
    controlDiv;

    /**
     * @type {d3.Selection}
     */
    contentControls;

    constructor(guiManager, message) {
        super(guiManager, message);

        d3.select("#game_content").html(HTML);

        this.quizDiv = d3.select("#quiz");
        
        if (this.stateInfo.role == ROLE.ADMIN) {
            this.controlDiv = d3.select("#game_controls").append("div")
                .attr("id", "quiz_controls");

            this.contentControls = this.controlDiv.append("div")
                .attr("id", "quiz_controls_questions");
        }

        this.update();
    }

    update() {
        if (this.stateInfo.role == ROLE.ADMIN) {
            this.contentControls.selectAll(".selector")
                .data(this.state.content, d => d.id)
                .join(enter => enter.append("div")
                    .attr("class", "selector")
                    .on("click", (_, d) => {
                        this.sendMessage(MESSAGE_TYPE.CHANGE_ACTIVE_CONTENT(d.id));
                    })
                )
                .classed("active", d => d.active)
                .text(d => d.selector_name);
        }
    }

    destroy() {
        this.quizDiv.remove();
        if (this.controlDiv) {
            this.controlDiv.remove();
        }
    }
}

const HTML = `
    <div id="quiz">
        <div id="quiz_content">

        </div>
    </div>
`;