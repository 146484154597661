import { Timer } from "../elements/timer.js";
import { GUIManager } from "../guiManager.js";
import { Subquestion_Clue } from "./clue.js";
import * as d3 from "d3"

/**
 * @enum {string}
 */
const ROLE = {
    ADMIN: 1,
    PLAYER: 2
}

/**
 * @typedef {Object} Subquestion_Clue_State
 * @property {PHASE} phase
 * @property {Object[]} hints
 * @property {Object} [hints[].hint]
 * @property {boolean} hints[].visible
 * @property {boolean} buzzed
 * @property {boolean} result_visible
 * @property {string} result
 */


/**
 * classid "subquestion_clue_emoji"
 * @extends Subquestion_Clue<Subquestion_Clue_State>
 * @abstract
 */
export class Subquestion_ClueEmoji extends Subquestion_Clue {

    /**
     * 
     * @param {GUIManager} guiManager 
     * @param {*} message 
     */
    constructor(guiManager, message) {
        super(guiManager, message);

        this.divHints.classed("emoji", true);
    }

    update() {
        super.update();

        let data = this.state.hints.map((h, i, a) => ({
            hint: h.hint,
            visible: h.visible,
            timer: !h.visible && (i > 0 ? a[i-1].visible : true)
        }));

        this.divHints.selectAll(".hint")
            .data(data)
            .join("div")
            .attr("id", d => (d.timer ? "timer_clue_emoji" : null))
            .attr("class", "hint")
            .classed("halfvisible", d => !d.visible && this.stateInfo.role == ROLE.ADMIN)
            .text(d => d.hint);
        this.divResult
            .select("div")
                .classed("rotateOut", !this.state.result_visible && this.stateInfo.role == ROLE.PLAYER)
                .classed("halfvisible", !this.state.result_visible && this.stateInfo.role == ROLE.ADMIN)
                .select("span")
                    .html(this.state.result);
    }
}

/**
 * classid "timer_clue_emoji"
 */
export class Timer_ClueEmoji extends Timer {
    constructor(guiManager, message) {
        super(guiManager, message, "timer_clue_emoji", true);
    }
}