import { GUIObject } from "./guiObject.js";
import * as d3 from "d3";
import '../styles/login.css';

export class Login extends GUIObject {

    constructor(guiManager, message) {
        super(guiManager, message);

        d3.select("#app").html(HTML);

        let discordSelect = d3.select("#login_discordInput");

        let firstElement = discordSelect.append("option")
            .property("value", "undefined")
            .html("Discord");
            
        discordSelect.on("change", () => firstElement.remove());

        for (let m of this.state.discordMembers) {
            discordSelect.append("option")
                .property("value", m.id)
                .html(m.name);
        }

        d3.select("#login_joinButton").on("click", function() {
            let name = d3.select("#login_nameInput").property("value");
            let discordid = discordSelect.property("value")
            if (name == "" || discordid == "undefined") {
                return;
            }
            this.sendMessage({
                type: "join",
                name: name,
                discordid: discordid
            });
        }.bind(this));

        d3.select("#login_hostButton").on("click", function() {
            let name = d3.select("#login_nameInput").property("value");
            let discordid = discordSelect.property("value")
            if (name == "" || discordid == "undefined") {
                return;
            }
            this.sendMessage({
                type: "host",
                name: name,
                discordid: discordid
            });
        }.bind(this));
    }

    destroy() {
        d3.select("#login").remove();
    }
}

const HTML = `
    <div id="login">
        <div id="login_nameInputDiv">
            <div>
                Name
            </div>
            <input id="login_nameInput">
        </div>
        <div id="login_discordInputDiv">
            <select id="login_discordInput">
            </select>
        </div>
        <div id="login_buttons">
            <div id="login_joinButton">
                Beitreten
            </div>
            <div id="login_hostButton">
                Erstellen
            </div>
        </div>
    </div>
`;