// import * as jsonpatch from "../lib/JSON-Patch-master/index.mjs"

import { Config } from "./config.js";
import * as d3 from "d3";
import * as jsonpatch from "fast-json-patch";
import { GUIManager } from "./guiManager.js";

/**
 * @typedef {import("d3")} d3
 */

/**
 * @template {Object} S
 * @template {Object} SI
 */
export class GUIObject {

    /**
     * @type {GUIManager}
     */
    guiManager;

    /**
     * @type {S}
     */
    state;

    /**
     * @type {S}
     */
    oldState;

    /**
     * @type {SI}
     */
    stateInfo;

    /**
     * @type {Config}
     */
    config;

    constructor(guiManager, message) {
        this.guiManager = guiManager;
        this.config = this.guiManager.config;

        if (message.state) {
            this.state = message.state;
        } else {
            this.state = {};
        }
        this.oldState = this.state;

        if (message.stateInfo) {
            this.stateInfo = message.stateInfo;
        } else {
            this.stateInfo = {};
        }
    }

    initFinished() {

    }

    /**
     * 
     */
    destroy() {

    }

    /**
     * @protected
     */
    sendMessage(message) {
        this.guiManager.sendMessage(this, message);
    }

    handleMessage(message) {
        
    }

    /**
     * 
     */
    update() {

    }

    patchState(message) {
        this.oldState = this.state;
        this.state = jsonpatch.deepClone(this.state);
        jsonpatch.applyPatch(this.state, message.patch);
        this.update();
    }
}