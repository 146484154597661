import { GUIObject } from "../guiObject.js";
import * as d3 from "d3"

/**
 * @enum {number}
 */
const MESSAGE_MINDGAME = {
    SHOW_BETS: 1,
    HIDE_ALL: 2,
    START_VOTING: 3
}

const TEXT_START_VOTING = "Abstimmung starten";
const TEXT_SHOW_BETS = "Gesetztes anzeigen";
const TEXT_HIDE_ALL = "Abstimmung verstecken";


export class Mindgame extends GUIObject {

    /**
     * @type {d3.Selection}
     */
    controlsDiv;


    constructor(guiManager, message) {
        super(guiManager, message);

        this.controlsDiv = d3.select(".question_controls");

        this.button1 = this.controlsDiv.append("div")
            .attr("class", "start_voting")
            .text(TEXT_START_VOTING)
            .on("click", () => this.sendMessage({
                type: MESSAGE_MINDGAME.START_VOTING
            }));
        this.button2 = this.controlsDiv.append("div")
            .attr("class", "show_bets")
            .text(TEXT_SHOW_BETS)
            .on("click", () => this.sendMessage({
                type: MESSAGE_MINDGAME.SHOW_BETS
            }));
        this.button3 = this.controlsDiv.append("div")
            .attr("class", "hide_all")
            .text(TEXT_HIDE_ALL)
            .on("click", () => this.sendMessage({
                type: MESSAGE_MINDGAME.HIDE_ALL
            }));
    }

    destroy() {
        this.button1.remove();
        this.button2.remove();
        this.button3.remove();
    }

}