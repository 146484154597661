
import * as d3 from "d3"

/**
 * @typedef {import("../guiObject").GUIObject} GUIObject
 * @typedef {import("d3")} d3
 */

export class PhaseButton {

    /**
     * @type {GUIObject}
     * @readonly
     * @protected
     */
    guiObject;

    /**
     * @type {Map<number,string>}
     * @readonly
     * @protected
     */
    phases;

    /**
     * @type {number}
     * @protected
     */
    phase;

    /**
     * @type {(number) => Object}
     */
    messageFunction;

    /**
     * @type {d3.Selection}
     * @readonly
     * @protected
     */
    button;

    /**
     * @param {GUIObject} guiObject 
     * @param {d3.Selection} selection 
     * @param {Map<number,string>} phases
     * @param {number} phase
     * @param {() => Object} [messageFunction] 
     */
    constructor(guiObject, selection, phases, phase, messageFunction = () => {return {type: "next_phase"}}) {

        this.guiObject = guiObject;
        this.phases = phases;
        this.messageFunction = messageFunction;

        this.button = selection.append("div")
            .attr("class", "standardButton phaseButton")
            .on("click", this.handleClick.bind(this));
        
        this.setPhase(phase);
    }

    /**
     * @protected
     */
    handleClick() {
        if (!this.phases.has(this.phase+1)) {
            return;
        }
        this.guiObject.sendMessage(this.messageFunction(this.phase));
    }

    /**
     * @param {number} phase 
     */
    setPhase(phase) {
        this.phase = phase;

        this.button.html("");
        for (let e of this.phases.entries()) {
            this.button.append("span")
                .classed("activePhase", this.phase == e[0])
                .html(e[1]);
        }
    }

    remove() {
        this.button.remove();
    }
}