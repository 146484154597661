
import { INFO_TYPE } from "../definitions/elements/info.defs.js";
import * as d3 from "d3";

import "../../styles/elements/hoverInfo.css";

/**
 * @type {number}
 */
const HOVERTIME = 500;

/**
 * @type {d3.Selection}
 */
var infoElement = null;

/**
 * @type {NodeJS.Timeout}
 */
var timeout = null;

/**
 * @param {d3.Selection} div 
 * @param {(d) => import("../definitions/elements/info.defs").Info} infoFunction 
 * @deprecated
 */
export function setInfo(div, infoFunction) {
    div.on("mousemove", (e, d) => {
        if (infoElement) return;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            createInfo(infoFunction(d), e.x, e.y);
            timeout = null;
        }, HOVERTIME);
    })
    .on("mouseleave", (_, __) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        /*if (!infoElement) return;
        if (infoElement.filter(":hover").empty()) {
            infoElement.remove();
            infoElement = null;
        }*/
    });
}

/**
 * @param {import("../definitions/elements/info.defs").Info} info 
 * @param {number} x 
 * @param {number} y 
 * @deprecated
 */
function createInfo(info, x, y) {
    if (!info || !(info.info || info.admin || info.result)) return;
    let combinedInfo = createCombinedInfo(info);
    if (!combinedInfo) return;
    if (combinedInfo.texts.length == 0 && combinedInfo.image_urls.length == 0 && combinedInfo.video_urls.length == 0 && combinedInfo.audio_urls.length == 0) return;
    infoElement = d3.select("#app").append("div")
        .attr("id", "hover_info")
        .style("left", x)
        .style("top", y)
        .on("mouseleave", (_, __) => {
            infoElement.remove();
            infoElement = null;
        });
    infoElement.append("div")
        .attr("class", "texts")
        .selectAll(".text")
        .data(combinedInfo.texts)
        .enter()
        .append("div")
            .attr("class", "text")
            .text(d => d);
}

/**
 * @param {import("../definitions/elements/info.defs").Info} info 
 * @return {?Info}
 * @deprecated
 */
function createCombinedInfo(info) {
    if (!info || !(info.info || info.admin || info.result)) return null;
    let combinedInfo = {
        texts: [],
        image_urls: [],
        video_urls: [],
        audio_urls: []
    }
    if (info.info) {
        if (info.info.texts) combinedInfo.texts = combinedInfo.texts.concat(info.info.texts);
        if (info.info.image_urls) combinedInfo.image_urls = combinedInfo.image_urls.concat(info.info.image_urls);
        if (info.info.video_urls) combinedInfo.video_urls = combinedInfo.video_urls.concat(info.info.video_urls);
        if (info.info.audio_urls) combinedInfo.audio_urls = combinedInfo.audio_urls.concat(info.info.audio_urls);
    }
    if (info.admin) {
        if (info.admin.texts) combinedInfo.texts = combinedInfo.texts.concat(info.admin.texts);
        if (info.admin.image_urls) combinedInfo.image_urls = combinedInfo.image_urls.concat(info.admin.image_urls);
        if (info.admin.video_urls) combinedInfo.video_urls = combinedInfo.video_urls.concat(info.admin.video_urls);
        if (info.admin.audio_urls) combinedInfo.audio_urls = combinedInfo.audio_urls.concat(info.admin.audio_urls);
    }
    if (info.result) {
        if (info.result.texts) combinedInfo.texts = combinedInfo.texts.concat(info.result.texts);
        if (info.result.image_urls) combinedInfo.image_urls = combinedInfo.image_urls.concat(info.result.image_urls);
        if (info.result.video_urls) combinedInfo.video_urls = combinedInfo.video_urls.concat(info.result.video_urls);
        if (info.result.audio_urls) combinedInfo.audio_urls = combinedInfo.audio_urls.concat(info.result.audio_urls);
    }
    return combinedInfo;
}

/**
 * @param {d3.Selection} div 
 */
export function createInfoDiv(div) {
    div.append("div")
        .attr("class", "info_div_outer")
        .append("div")
            .attr("class", "info_div_inner");
}

/**
 * @param {d3.Selection} div 
 * @param {(d) => import("../definitions/elements/info.defs").Info} infoFunction 
 */
export function updateInfoDiv(div, infoFunction) {
    let infoDiv = div.select(".info_div_outer");

    infoDiv.classed("empty", d => {
        let data = infoFunction(d);
        return !data || data.elements.length == 0;
    });

    infoDiv.select(".info_div_inner").filter(d => infoFunction(d)).selectAll(".info_text")
        .data(d => infoFunction(d).elements.filter(e => e.type == INFO_TYPE.TEXT))
        .join(enter => enter.append("div")
            .attr("class", "info_text"))
        .text(d => d.value);
}

/**
 * @param {Object} props 
 * @param {import("../definitions/elements/info.defs.js").Info} [props.info]
 * @param {boolean} [props.fadeDown]
 * @returns {React.JSX.Element}
 */
export function CHoverInfo({info, fadeDown=false}) {
    if (!info) return null;
    const items = info.elements.map((e, i) => {
        let classes = "";
        if (e.admin) classes += "admin";
        if (e.result) classes += "result";
        switch (e.type) {
            case INFO_TYPE.TEXT:
                return (
                    <div key={i} className={classes}>{e.value}</div>
                );
            default:
                return null;
        }
    });
    let classes = "hoverInfo";
    if (fadeDown) classes += " fadeDown";
    return (
        <div className={classes}>
            <div className="hoverInfo_inner">
                {items}
            </div>
        </div>
    );
}