import { GUIManager } from "../guiManager.js";
import { GUIObject } from "../guiObject.js";
import * as d3 from "d3";

/**
 * @typedef State_Scrollinglist
 * @property {Object[]} questions
 * @property {number} questions[].id
 * @property {string} questions[].secrettitle
 * @property {string} questions[].secrettitle_visible
 * @property {string} questions[].category
 * @property {string} questions[].category_visible
 * @property {string} questions[].type
 * @property {string} questions[].type_visible
 * @property {boolean} questions[].played
 * @property {boolean} questions[].out
 * @property {boolean} questions[].selected
 * @property {boolean} questions[].visible
 * @property {number} active_team
 */

/**
 * @enum {number}
 */
const ROLE = {
    /**
     * - See everything.
     * - Edit everything.
     */
    ADMIN: 1,
    /**
     * - See only selectable questions.
     * - Edit nothing.
     */
    SPECTATOR: 2,
    /**
     * - See only selectable questions.
     * - Select question if active.
     */
    TEAM: 3
}

/**
 * @enum {() => Object}
 */
const MESSAGE_TYPE = {
    /**
     * Change the selected question.
     * Only {@link ROLE.ADMIN} or {@link ROLE.TEAM} if there is not already a question selected and the team is active.
     * @param {number} id
     */
    SELECT_QUESTION: id => { return {
        type: 1,
        id: id
    }},
    /**
     * Displays the selected question.
     * Only {@link ROLE.ADMIN}
     */
    PLAY_SELECTED_QUESTION: () => { return {
        type: 2
    }},
    /**
     * Loads new questions and let the next team select one.
     * Only {@link ROLE.ADMIN}
     */
    NEXT_SELECTION: () => { return {
        type: 3
    }}
}

/**
 * classid "question_selection_scrollinglist"
 * @extends GUIObject<State_Scrollinglist>
 */
export class QuestionSelectionScrollinglist extends GUIObject {

    /**
     * Html container for all the content of this class.
     * @type {d3.Selection}
     */
    scrollinglistDiv;

    /**
     * @type {d3.Selection}
     */
    questionsDiv;

    /**
     * @type {d3.Selection}
     */
    controlDiv;

    /**
     * @type {d3.Selection}
     */
    button_playQuestion;

    /**
     * @type {d3.Selection}
     */
    button_nextSelection;

    /**
     * 
     * @param {GUIManager} guiManager 
     * @param {Object} message 
     */
    constructor(guiManager, message) {
        super(guiManager, message);

        d3.select("#quiz_content").html(HTML_SCROLLINGLIST);

        this.scrollinglistDiv = d3.select("#question_selection_scrollinglist");

        this.questionsDiv = this.scrollinglistDiv.select("#question_selection_scrollinglist_questionsDiv");

        if (this.stateInfo.role == ROLE.ADMIN) {
            this.controlDiv = this.scrollinglistDiv.select("#question_selection_scrollinglist_controls")
                .classed("hidden", false);
            this.button_playQuestion = this.controlDiv.append("div")
                .attr("class", "standardButton invisible")
                .text("Frage spielen")
                .on("click", () => this.sendMessage(MESSAGE_TYPE.PLAY_SELECTED_QUESTION()));
            this.button_nextSelection = this.controlDiv.append("div")
                .attr("class", "standardButton invisible")
                .text("Neue Fragen")
                .on("click", () => this.sendMessage(MESSAGE_TYPE.NEXT_SELECTION()));
        }

        this.questionsDiv.selectAll(".question")
            .data(this.state.questions.filter(q => q.visible), d => d.id)
            .join(enter => enter.append("div")
                .attr("class", "question")
                .html(HTML_QUESTION)
                .call(div => div.select(".question_secrettitle")
                    .on("click", (_, d) => {
                        if (!(((this.stateInfo.role == ROLE.TEAM && this.stateInfo.team_id == this.state.active_team)
                            || this.stateInfo.role == ROLE.ADMIN)
                            && !this.state.questions.some(q => q.selected || (q.visible && q.played)))) return;
                        this.sendMessage(MESSAGE_TYPE.SELECT_QUESTION(d.id));
                    })
                ))

        this.update();
    }

    update() {
        this.questionsDiv.classed("selectable", ((this.stateInfo.role == ROLE.TEAM && this.stateInfo.team_id == this.state.active_team)
            || this.stateInfo.role == ROLE.ADMIN)
            && !this.state.questions.some(q => q.selected || (q.visible && q.played)));

        this.questionsDiv.selectAll(".question")
            .data(this.state.questions.filter(q => q.visible), d => d.id)
            .join(enter => enter.append("div")
                .attr("class", "question fadeIn")
                .on("animationend", /** @param {TransitionEvent} e */ e => d3.select(e.currentTarget)
                    .classed("fadeIn", false)
                    .on("animationend", null))
                .html(HTML_QUESTION)
                .call(div => div.select(".question_secrettitle")
                    .on("click", (_, d) => {
                        if (!(((this.stateInfo.role == ROLE.TEAM && this.stateInfo.team_id == this.state.active_team)
                            || this.stateInfo.role == ROLE.ADMIN)
                            && !this.state.questions.some(q => q.selected || (q.visible && q.played)))) return;
                        this.sendMessage(MESSAGE_TYPE.SELECT_QUESTION(d.id));
                    })
                ),
                update => update,
                exit => exit.classed("fadeOut", true)
                    .on("transitionend", /** @param {TransitionEvent} e */ e => d3.select(e.currentTarget).remove())
            )
            .classed("selected", d => d.selected)
            .classed("played", d => d.played)
            .call(div => div.select(".question_category")
                .classed("fadeInPrepared", d => !d.category_visible)
                .filter(d => d.category_visible).each((d, i, a) => d3.select(a[i])
                    .classed(d.category , true)))
            .call(div => div.select(".question_secrettitle")
                .text(d => d.secrettitle_visible ? d.secrettitle : "?"))
            .call(div => div.select(".question_type")
                .classed("fadeInPrepared", d => !d.type_visible)
                .filter(d => d.type_visible).each((d, i, a) => d3.select(a[i])
                    .classed(d.type , true)));

        if (this.stateInfo.role == ROLE.ADMIN) {
            this.button_playQuestion.classed("invisible", !this.state.questions.some(q => q.selected));
            this.button_nextSelection.classed("invisible", !this.state.questions.some(q => q.played && q.visible));
        }
    }

    destroy() {
        super.destroy();
        this.scrollinglistDiv.remove();
    }
}

const HTML_SCROLLINGLIST = `
    <div id="question_selection_scrollinglist">
        <div id="question_selection_scrollinglist_questionsDiv_outer">
            <div id="question_selection_scrollinglist_questionsDiv">
                
            </div>
        </div>
        <div id="question_selection_scrollinglist_controls" class="hidden"></div>
    </div>
`;

const HTML_QUESTION = `
    <div class="question_category"></div>
    <div class="question_secrettitle"></div>
    <div class="question_type"></div>
`;