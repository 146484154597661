
import "../../styles/elements/contentControls.css";


/**
 * @param {Object} props 
 * @param {React.JSX.Element} props.children
 * @return {React.JSX.Element}
 */
export function CQuestionContentControls({children}) {
    return (
        <div className="question_content_controls">
            {children}
        </div>
    );
}


/**
 * @param {Object} props 
 * @param {string | React.JSX.Element} props.children
 * @param {boolean} props.visible
 * @param {() => void} props.onClick
 * @return {React.JSX.Element}
 */
export function CQuestionContentControlsButton({children, visible, onClick}) {
    const classes = "question_content_controls_button" + (visible ? "" : " invisible");
    return (
        <div className={classes} onClick={onClick}>
            {children}
        </div>
    );
}


/**
 * @param {Object} props 
 * @param {Map<number,string>} props.phases 
 * @param {number} props.currentPhase 
 * @param {() => void} props.onNextPhase
 * @return {React.JSX.Element}
 */
export function CQuestionContentControlsPhaseButton({phases, currentPhase, onNextPhase}) {
    const phaseElements = new Array(...phases.entries()).map(e => {
        const elementClass = e[0] == currentPhase ? "current_phase" : null;
        return (
            <span key={e[0]} className={elementClass}>{e[1]}</span>
        );
    });

    return (
        <CQuestionContentControlsButton visible={true} onClick={onNextPhase}>
            <div className="phase_button_inner">
                {phaseElements}
            </div>
        </CQuestionContentControlsButton>
    );
}



/**
 * @param {Object} props 
 * @param {string} props.text 
 * @param {boolean} [props.square] 
 * @param {() => void} props.onClick
 * @return {React.JSX.Element}
 */
export function CControlButtonMini({text, square=false, onClick}) {
    let classes = "content_controls_button_mini";
    if (square) classes += " square";
    return (
        <div className={classes} onClick={onClick}>
            <span>{text}</span>
        </div>
    );
}
