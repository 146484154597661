
/**
 * @typedef State
 * @property {QuestionInfo} question_info
 * @property {number} num_subquestions
 * @property {number} current_subquestion
 * @property {string} score_info
 */

/**
 * @typedef QuestionInfo
 * @property {string} id
 * @property {string} category
 * @property {string} type
 * @property {string} secrettitle
 * @property {string} title
 * @property {string} [subtitle]
 * @property {import("../elements/info.defs").Info} [info]
 * @property {string} background_image
 */

/**
 * @typedef StateInfo
 * @property {ROLE} role
 */

/**
 * @enum {number}
 */
export const ROLE = {
    /**
     * - See everything.
     */
    ADMIN: 1,
    /**
     * - See only public infos.
     */
    NOADMIN: 2
}


/**
 * @type {string}
 */
export const CLASSID_QUESTION = "question";

/**
 * @type {string}
 */
export const CLASSID_HEADER = "question_header";
