import { GUIObject } from "../guiObject.js";
import * as d3 from "d3"

/**
 * @enum {string}
 * @private
 */
const MESSAGE_TYPE = {
    /**
     * Change the active team.
     * Only {@link ROLE.ADMIN}.
     * @param {number} team_id
     */
    ACTIVE_TEAM: 1,
    /**
     * Removes one mistake from a team.
     * Only {@link ROLE.ADMIN}
     * @param {number} team_id
     */
    REMOVE_MISTAKE: 2,
    /**
     * Adds one mistake to a team.
     * Only {@link ROLE.ADMIN}
     * @param {number} team_id
     */
    MISTAKE: 3,
    /**
     * Kicks a team out.
     * Only {@link ROLE.ADMIN}
     * @param {number} team_id
     */
    TEAM_OUT: 4,
    /**
     * Sets the next team in order active.
     * Only {@link ROLE.ADMIN}
     */
    NEXT_TEAM_ACTIVE: 5,
    /**
     * Adds a skip to a team.
     * Only {@link ROLE.ADMIN}
     * @param {number} team_id
     */
    ADD_SKIP: 6,
    /**
     * Removes a skip from a team.
     * Only {@link ROLE.ADMIN}
     * @param {number} team_id
     */
    REMOVE_SKIP: 7
}

/**
 * @enum {number}
 * @private
 */
const ROLE = {
    /**
     * - See everything.
     * - Edit everything.
     */
    ADMIN: 1,
    /**
     * - See everything.
     * - Edit nothing.
     */
    NOADMIN: 2
}

/**
 * @typedef State_Roundmanager_OutSkips
 * @property {boolean} [skips]
 * @property {number} [maxMistakes]
 * @property {Object[]} teams 
 * @property {boolean} teams[].id 
 * @property {boolean} teams[].active 
 * @property {boolean} [teams[].mistakes] 
 * @property {boolean} [teams[].out] 
 * @property {boolean} [teams[].skips] 
 */


/**
 * classid "roundmanager"
 * @extends GUIObject<State_Roundmanager_OutSkips>
 */
export class RoundManager extends GUIObject {

    /**
     * @type {d3.Selection}
     */
    scoreboard;

    constructor(guiManager, message) {
        super(guiManager, message);

        this.scoreboard = d3.select("#scoreboard");

        this.update();
    }

    update() {
        this.scoreboard.selectAll(".scoreboard_team").select(".scoreboard_team_innerContent").selectAll(".scoreboard_team_roundmanger_inner")
            .data(d => [this.state.teams.find(t => t.id == d.id)], d => d.id)
            .join(
                enter => {
                    let div = enter.append("div")
                        .attr("class", "scoreboard_team_roundmanger_inner");

                    if (this.stateInfo.role == ROLE.ADMIN) {
                        let controls = div.append("div")
                            .attr("class", "scoreboard_team_roundmanger_controls")
                        controls.append("div")
                            .attr("class", "scoreboard_team_roundmanger_controls_active")
                            .on("click", (_, d) => {
                                let message = {
                                    type: MESSAGE_TYPE.ACTIVE_TEAM,
                                    team_id: d.id
                                }
                                this.sendMessage(message);
                            });
                        if (this.state.maxMistakes) {
                            controls.append("div")
                                .attr("class", "scoreboard_team_roundmanger_controls_mistake")
                                .on("click", (_, d) => {
                                    let message = {
                                        type: MESSAGE_TYPE.MISTAKE,
                                        team_id: d.id
                                    }
                                    this.sendMessage(message);
                                });
                            controls.append("div")
                                .attr("class", "scoreboard_team_roundmanger_controls_out")
                                .on("click", (_, d) => {
                                    let message = {
                                        type: MESSAGE_TYPE.TEAM_OUT,
                                        team_id: d.id
                                    }
                                    this.sendMessage(message);
                                });
                            controls.append("div")
                                .attr("class", "scoreboard_team_roundmanger_controls_remove_mistake")
                                .on("click", (_, d) => {
                                    let message = {
                                        type: MESSAGE_TYPE.REMOVE_MISTAKE,
                                        team_id: d.id
                                    }
                                    this.sendMessage(message);
                                });
                        }
                        if (this.state.skips) {
                            controls.append("div")
                                .attr("class", "scoreboard_team_roundmanger_controls_add_skip")
                                .on("click", (_, d) => {
                                    let message = {
                                        type: MESSAGE_TYPE.ADD_SKIP,
                                        team_id: d.id
                                    }
                                    this.sendMessage(message);
                                });
                            controls.append("div")
                                .attr("class", "scoreboard_team_roundmanger_controls_remove_skip")
                                .on("click", (_, d) => {
                                    let message = {
                                        type: MESSAGE_TYPE.REMOVE_SKIP,
                                        team_id: d.id
                                    }
                                    this.sendMessage(message);
                                });
                        }
                    }
                    return div;
                },
                update => update
            )
            .classed("active", d => d.active)
            .classed("out", d => d.out);
        if (this.state.maxMistakes && this.state.maxMistakes > 1) {
            this.scoreboard.selectAll(".scoreboard_team").select(".scoreboard_team_outerContent").selectAll(".scoreboard_team_roundmanger_outer")
                .data(d => [this.state.teams.find(t => t.id == d.id)], d => d.id)
                .join(
                    enter => {
                        let div = enter.append("div")
                            .attr("class", "scoreboard_team_roundmanger_outer");
                        
                        let mistakes = div.append("div")
                            .attr("class", "scoreboard_team_roundmanger_mistakes");
                        for (let i = 0; i < this.state.maxMistakes; i++) {
                            mistakes.append("div")
                                .attr("class", "scoreboard_team_roundmanger_mistake");
                        }
                        
                        return div;
                    },
                    update => update
                )
                .each(function(d) {
                        d3.select(this).selectAll(".scoreboard_team_roundmanger_mistake")
                            .classed("mistake", (_, i) => d.mistakes > i);
                    });
        }
        if (this.state.skips) {
            this.scoreboard.selectAll(".scoreboard_team").select(".scoreboard_team_outerContent").selectAll(".scoreboard_team_roundmanger_outer")
                .data(d => [this.state.teams.find(t => t.id == d.id)], d => d.id)
                .join(
                    enter => {
                        let div = enter.append("div")
                            .attr("class", "scoreboard_team_roundmanger_outer");
                        
                        let skips = div.append("div")
                            .attr("class", "scoreboard_team_roundmanger_skips");
                        skips.append("div")
                            .attr("class", "scoreboard_team_roundmanger_skip");
                        skips.append("div")
                            .attr("class", "scoreboard_team_roundmanger_skip_number");
                        
                        return div;
                    },
                    update => update
                )
                .call(div => div.selectAll(".scoreboard_team_roundmanger_skip")
                    .classed("skip", d => d.skips > 0))
                .call(div => div.selectAll(".scoreboard_team_roundmanger_skip_number")
                    .text(d => d.skips > 1 ? "x" + d.skips : ""));
        }
    }

    destroy() {
        this.scoreboard.selectAll(".scoreboard_team_roundmanger_inner").remove();
        this.scoreboard.selectAll(".scoreboard_team_roundmanger_outer").remove();
    }
}