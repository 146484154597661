
export const SETTINGS = {
    NOTE_MAX_TEXT_LENGTH: 50,
    NOTE_MAX_COUNT: 100,
    DEFAULT_TIMER_PREPARATION: 180,
    DEFAULT_TIMER_ANSWER: 15
};

/**
 * @typedef Config
 * @property {number} [timer_preparation] defaults to {@link DEFAULT_TIMER_PREPARATION}
 * @property {number} [timer_answer] defaults to {@link DEFAULT_TIMER_ANSWER}
 * @property {MODE} mode
 * @property {Object[]} answers
 * @property {string} answers[].text
 * @property {string} [answers[].predefined]
 * @property {import("../elements/info.defs.js").Info} [answers[].info]
 * @property {number} [mistakes] defaults to 1
 */

/**
 * @typedef State
 * @property {PHASE} phase
 * @property {MODE} mode
 * @property {Answer[]} answers
 * @property {boolean} result_infos_visible
 * @property {number} max_text_length
 * @property {number} [moderated_team_id] -1 if no moderated team
 * @property {TeamNote[]} notes
 * 
 * @typedef Answer
 * @property {number} id
 * @property {string} [text]
 * @property {boolean} visible
 * @property {string} [predefined]
 * @property {number} [order]
 * @property {SUGGESTION} [suggestion]
 * @property {import("../elements/hoverInfo.js").HoverInfo} [info]
 * 
 * @typedef TeamNote
 * @property {number} team_id
 * @property {NoteEntry[]} entries
 * 
 * @typedef NoteEntry
 * @property {number} id
 * @property {number} predefined_id -1 if none
 * @property {NOTE_TAG} tag
 * @property {string} text max length {@link SETTINGS.NOTE_MAX_TEXT_LENGTH}
 * @property {boolean} suggested 
 */

/**
 * @typedef StateInfo
 * @property {boolean} isAdmin
 * @property {boolean} isModerator
 */


/**
 * @enum {string}
 */
export const MODE = {
    CALLED: "called",
    ORDERED: "ordered",
    NUMBERED: "numbered",
    PREDEFINED: "predefined",
    ASSIGN: "assign"
};

/**
 * @enum {number}
 */
export const SUGGESTION = {
    INVALID: -1,
    RIGHT: 1,
    WRONG: 2,
    MAYBE: 3
};

/**
 * @enum {number}
 */
export const NOTE_TAG = {
    NONE: 0,
    SURE: 1,
    UNSURE: 2,
    WRONG: 3
};

/**
 * @enum {number}
 */
export const PHASE = {
    START: 1,
    QUESTION: 2,
    PREPARATION: 3,
    PREPARATION_END: 4,
    ANSWERS: 5,
    RESULTS: 6,
    FINISHED: 7
};

/**
 * @enum {number}
 */
export const ROLE = {
    ADMIN: 1,
    MODERATOR: 2,
    TEAM: 3,
    SPECTATOR: 4
};

/**
 * @enum {number}
 */
export const MESSAGE = {
    /**
     * {@link PHASE.ANSWERS} or {@link PHASE.RESULTS}
     * @param {number} answer_id
     */
    SHOW_ANSWER: 1,
    /**
     * {@link PHASE.ANSWERS}
     */
    WRONG_ANSWER: 2,
    /**
     * {@link PHASE.RESULTS}
     */
    SHOW_INFOS: 3,
    /**
     * @param {PHASE} current_phase
     */
    NEXT_PHASE: 4,
    /**
     * {@link PHASE.ANSWERS}
     * @param {number} answer_id
     * @param {number} suggestion
     */
    SUGGEST_ANSWER: 5,
    /**
     * Adds a note.
     * Only if not predefined.
     * @param {string} text
     */
    ADD_NOTE: 6,
    /**
     * Edits a note.
     * @param {number} note_id
     * @param {string} text
     */
    EDIT_NOTE: 7,
    /**
     * Removes a note.
     * Only if not predefined.
     * @param {number} note_id
     */
    REMOVE_NOTE: 8,
    /**
     * Sets the tag of a note.
     * @param {number} note_id
     * @param {string} tag
     */
    SET_NOTE_TAG: 9,
    /**
     * Sets the predefined of a note.
     * @param {number} note_id
     * @param {number} predefined_id
     */
    SET_NOTE_PREDEFINED: 10,
    /**
     * Sets the suggested value of a note.
     * @param {number} note_id
     * @param {boolean} suggested
     */
    SET_NOTE_SUGGESTED: 11,
    /**
     * Change the moderated team.  
     * @param {number} team_id -1 if no team view is active
     */
    MODERATED_TEAM: 12
};

export const CLASSID = "question_list";
